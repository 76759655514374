import React, { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import Head from 'next/head';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Profile from 'components/Profile';
import TimelineSection from 'components/TimelineSection';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import {
  GetTimelinePostsResult,
  GetUserProfileByUsernameResult,
  PostParentKey,
  ProfilePageTab,
  User,
} from 'common/interfaces/api';
import { SITE_BASE_URL } from 'config/env';
import Icon from 'components/common/Icon';
import { parseJSON, stringifyJSON, useMediaQuery } from 'common/utils';
import styles from './ProfilePage.module.scss';
import CreatePostBox from 'components/CreatePostBox';
import { NextPageContext } from 'next';
import { useState } from 'react';
import { getUserProfile } from 'service/user';
import { generateCookieList } from 'common/utils/trackingTag';
import { parse } from 'cookie';
import InputProfileSection from 'components/modules/InputProfileSection';
import { useRouter } from 'next/router';
import UserCoursesList from 'components/UserCoursesList';
import { isAbleToUpgradePlan } from 'common/utils/user';
import { track } from '@amplitude/analytics-browser';
import { isRenewPrimeSubStatus } from 'common/utils/payment';

type ProfilePageProps = {
  username: string;
  userProfile: string | null;
};

const ProfilePage = (props: ProfilePageProps) => {
  const username = props.username;
  const [userProfile, setUserProfile] = useState(
    parseJSON(props.userProfile) as User
  );
  const router = useRouter();
  const tab = router.query.tab as string;
  const paywall = router.query.paywall as string;
  const [autoShowedPaywall, setAutoShowedPaywall] = useState(false);
  const currentUser = useStore((state) => state.currentUser);
  const isCurrentUser = username === currentUser?.username;
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const shouldRefetchCurrentProfile = useStore(
    (state) => state.shouldRefetchCurrentProfile
  );
  const shouldRefetchProfile = useStore((state) => state.shouldRefetchProfile);
  const showPaymentDialog = useGlobalStore((state) => state.showPaymentDialog);
  const [selectedTab, setSelectedTab] = useState(0);
  const [editingProfileCount, setEditingProfileCount] = useState(0);
  useEffect(() => {
    if (tab === ProfilePageTab.ProfileInput) {
      if (isCurrentUser) {
        setSelectedTab(2);
      } else {
        setSelectedTab(1);
      }
    } else if (tab === ProfilePageTab.SavedPosts) {
      if (isCurrentUser) {
        setSelectedTab(1);
      } else {
        setSelectedTab(0);
      }
    } else {
      setSelectedTab(0);
    }
  }, [isCurrentUser, tab]);

  const updateTabParam = useCallback(
    (i: number) => {
      let tab: string;
      if (i === 0) {
        tab = ProfilePageTab.PostsList;
      } else if (i === 1) {
        if (isCurrentUser) {
          tab = ProfilePageTab.SavedPosts;
        } else {
          tab = ProfilePageTab.ProfileInput;
        }
      } else if (i === 2) {
        if (isCurrentUser) {
          tab = ProfilePageTab.ProfileInput;
        } else {
          tab = ProfilePageTab.PostsList;
        }
      }
      if (tab) {
        void router.push(
          {
            pathname: `/${username}`,
            query: {
              tab: tab,
            },
          },
          `/${username}?tab=${tab}`,
          { shallow: true }
        );
      }
    },
    [isCurrentUser, router, username]
  );

  const handleSelectTab = (i) => {
    updateTabParam(i);
  };

  useEffect(() => {
    if (isCurrentUser) return;
    if (isAuthInit && userProfile?.id) {
      track('View Creator Profile', {
        creator_user_id: userProfile?.id?.toString(),
        creator_badge: userProfile?.badge,
        creator_follower_number: userProfile?.follow_count,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile?.id]);

  useEffect(() => {
    if (tab !== ProfilePageTab.ProfileInput) return;
    const routeChangeStart = (url: string) => {
      if (!isCurrentUser) return;
      if (tab !== ProfilePageTab.ProfileInput) return;
      if (editingProfileCount <= 0) return;
      if (url.startsWith(`/${currentUser?.username}?tab=profile`)) return;
      const ok = confirm(
        'プロフィールの変更が保存されていません。ページから移動しますか。'
      );
      if (!ok) {
        router.events.emit('routeChangeError');
        throw 'Abort route change. Please ignore this error.';
      }
    };

    router.events.on('routeChangeStart', routeChangeStart);
    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentUser, tab, editingProfileCount]);

  useEffect(() => {
    if (autoShowedPaywall) return;
    if (!currentUser?.id || isCurrentUser || paywall !== 'true') return;
    if (!userProfile?.is_creator) return;
    const isAbleToSub =
      userProfile?.is_creator && userProfile?.is_open_for_subscription;
    const canUpGradePlan = isAbleToUpgradePlan(userProfile);
    const isSubscribed = userProfile?.is_subscribed;
    const renewMode = isRenewPrimeSubStatus(userProfile);
    const showingSubButton =
      userProfile?.is_creator && (!isSubscribed || renewMode || canUpGradePlan);
    if (!showingSubButton || !isAbleToSub) return;
    showPaymentDialog({ creator: userProfile });
    setAutoShowedPaywall(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoShowedPaywall,
    currentUser?.id,
    paywall,
    showPaymentDialog,
    userProfile?.id,
  ]);
  const isMobile = useMediaQuery('(max-width: 767px)');
  // profile
  const { refetch, isError } = useQuery(
    ['getProfile', username],
    async () => {
      if (!username) return Promise.reject();
      return getUserProfile(username);
    },
    {
      enabled: isAuthInit && !!username,
      refetchOnWindowFocus: false,
      retry: (count, e) => {
        const error = e as AxiosError;
        if (error.response?.status === 404) {
          return false;
        }
        if (count >= 2) {
          return false;
        }
        return true;
      },
      onSuccess: (data) => {
        const profile = data?.data;
        setUserProfile(profile);
      },
    }
  );

  useEffect(() => {
    if (shouldRefetchCurrentProfile) {
      void refetch();
    }
  }, [shouldRefetchCurrentProfile, refetch]);

  useEffect(() => {
    if (shouldRefetchProfile && isCurrentUser) {
      void refetch();
    }
  }, [shouldRefetchProfile, refetch, isCurrentUser]);

  // Metadata
  const titleText =
    (userProfile?.name ? userProfile.name : '') +
    (userProfile?.username ? ` (@${userProfile.username})` : '') +
    (userProfile ? ' | ' : '') +
    'PostPrime | 投資を楽しく学べる！';

  const getNextPageParamSavedPost = useCallback(
    (lastPage: GetTimelinePostsResult, pages: GetTimelinePostsResult[]) => {
      const posts = lastPage?.data?.posts;
      if (posts && posts.length > 0) {
        return pages.length + 1;
      }
    },
    []
  );

  const getNextPageParamProfile = useCallback(
    (lastPage: GetTimelinePostsResult) => {
      const posts = lastPage?.data?.posts;
      if (posts && posts.length > 0 && lastPage?.data?.next_key) {
        return lastPage?.data?.next_key;
      }
    },
    []
  );

  const canonicalUrl =
    userProfile?.username === 'PostPrime'
      ? 'https://postprime.com'
      : `${SITE_BASE_URL}/${username}`;
  return (
    <>
      <Head>
        {/* HTML Meta Tags */}
        <title>{titleText}</title>
        <meta
          name="description"
          content={`${
            userProfile?.bio
              ? userProfile?.bio
              : 'ログインして、SNSでお金を学ぼう!'
          }`}
        />

        {/* Facebook Meta Tags */}
        <meta
          property="og:url"
          content={`${SITE_BASE_URL}/${username}`}
          key="ogurl"
        />
        <meta property="og:type" content="profile" key="ogtype" />
        <meta property="og:title" content={titleText} />
        <meta
          property="og:description"
          content={`${
            userProfile?.bio
              ? userProfile?.bio
              : 'ログインして、SNSでお金を学ぼう!'
          }`}
        />
        {userProfile?.avatar_url && (
          <meta
            property="og:image"
            content={userProfile?.avatar_url || ''}
            key="ogimage"
          />
        )}

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`${SITE_BASE_URL}/${username}`} />
        <meta name="twitter:title" content={titleText} />
        <meta
          name="twitter:description"
          content={`${
            userProfile?.bio
              ? userProfile?.bio
              : 'ログインして、SNSでお金を学ぼう!'
          }`}
        />
        {userProfile?.avatar_url && (
          <meta
            name="twitter:image"
            content={userProfile?.avatar_url || ''}
            key="twimage"
          />
        )}

        <link rel="canonical" href={canonicalUrl} />
      </Head>
      {isError ? (
        <div className={styles.emptyWrapper}>
          <Icon name="empty" width={96} height={96} />
          <div className={styles.emptyText}>このユーザーが存在しません</div>
        </div>
      ) : (
        userProfile && (
          <div className={styles.profilePageWrapper}>
            <Profile user={userProfile} hideBorder={true} />
            {currentUser ? (
              <Tabs
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                selectedTabClassName={styles.selected}
                selectedIndex={selectedTab}
                onSelect={handleSelectTab}
              >
                <TabList className={styles.tabList}>
                  <Tab className={styles.tab} id="yourPosts">
                    <div className={styles.tabContent}>
                      {isCurrentUser ? 'あなたの投稿' : '投稿一覧'}
                    </div>
                  </Tab>
                  {isCurrentUser && (
                    <Tab className={styles.tab} id="savedPosts">
                      <div className={styles.tabContent}>保存した投稿</div>
                    </Tab>
                  )}
                  <Tab className={styles.tab} id="proifleInfo">
                    <div className={styles.tabContent}>プロフィール</div>
                  </Tab>
                </TabList>
                <TabPanel>
                  <div className={styles.timelineSection}>
                    {isCurrentUser && !isMobile && (
                      <CreatePostBox isProfilePage />
                    )}
                    {userProfile && <UserCoursesList creator={userProfile} />}
                    {userProfile?.id && (
                      <TimelineSection
                        apiUrl={`/profiles/timelines/v2/${userProfile?.id}`}
                        queryKey={[
                          PostParentKey.ProfilePosts,
                          userProfile?.id?.toString(),
                        ]}
                        queryParamKey="next_key"
                        noResultMessage="まだ投稿がありません"
                        getNextPageParam={getNextPageParamProfile}
                        defaultPageParam=""
                      />
                    )}
                  </div>
                </TabPanel>
                {isCurrentUser && (
                  <TabPanel>
                    <div className={styles.timelineSection}>
                      {userProfile?.id && (
                        <TimelineSection
                          apiUrl={`/posts/${userProfile?.id}/saved_posts`}
                          queryKey={[
                            PostParentKey.SavePosts,
                            userProfile?.id?.toString(),
                          ]}
                          queryParamKey="page"
                          noResultMessage="保存した投稿がありません"
                          getNextPageParam={getNextPageParamSavedPost}
                          defaultPageParam={1}
                        />
                      )}
                    </div>
                  </TabPanel>
                )}
                <TabPanel>
                  {userProfile && (
                    <InputProfileSection
                      onlyViewMode={!isCurrentUser}
                      userId={userProfile?.id}
                      setEditingProfileCount={setEditingProfileCount}
                    />
                  )}
                </TabPanel>
              </Tabs>
            ) : (
              <div className={styles.timelineSection}>
                {userProfile?.id && (
                  <TimelineSection
                    apiUrl={`/profiles/timelines/v2/${userProfile?.id}`}
                    queryKey={[
                      PostParentKey.ProfilePosts,
                      userProfile?.id?.toString(),
                    ]}
                    queryParamKey="next_key"
                    noResultMessage="まだ投稿がありません"
                    getNextPageParam={getNextPageParamProfile}
                    defaultPageParam=""
                  />
                )}
              </div>
            )}
          </div>
        )
      )}
    </>
  );
};

export const getServerSideProps = async (context: NextPageContext) => {
  const username = context.query.username as string;
  const referral = context.query.referral as string;
  const cookiesList = generateCookieList(
    context.query,
    parse(context.req?.headers?.cookie || '')
  );
  if (cookiesList.length > 0) {
    context.res.setHeader('Set-Cookie', cookiesList);
  }

  let profileData: GetUserProfileByUsernameResult;
  try {
    profileData = await getUserProfile(username, true, referral);
    return {
      props: {
        username: username,
        userProfile: stringifyJSON(profileData?.data) as string,
      },
    };
  } catch (error) {
    return {
      props: {
        username: username,
        userProfile: null,
      },
    };
  }
};
export default ProfilePage;
