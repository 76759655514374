import classNames from 'classnames';
import { useOutsideClick } from 'common/utils';
import { FC, useMemo, useRef, useState } from 'react';
import Icon from 'components/common/Icon';
import styles from './Dropdown.module.scss';

interface MenuItemType {
  label: string;
  value: string | number;
  customLabel?: string;
}

interface DropdownProps {
  items: MenuItemType[];
  currentValue: string | number;
  onChangeValue: (itemValue: string | number) => void;
  className?: string;
  placeholder?: string;
  title: string;
  isToggleRight?: boolean;
  limitHeightItems?: boolean;
  showCustomLabel?: boolean;
}
const Dropdown: FC<DropdownProps> = ({
  items,
  onChangeValue,
  className,
  currentValue,
  placeholder = '選択してください',
  title,
  isToggleRight = false,
  limitHeightItems = false,
  showCustomLabel = false,
}) => {
  const [show, setShow] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const currentItem = useMemo(() => {
    return items.find((i) => i.value === currentValue);
  }, [currentValue, items]);
  useOutsideClick(dropdownRef, () => {
    if (show) setShow(false);
  });
  return (
    <div
      className={classNames(styles.dropdown, className ?? '')}
      ref={dropdownRef}
    >
      <div
        className={classNames(styles.value, 'value')}
        onClick={() => setShow(!show)}
        tabIndex={0}
      >
        {currentItem
          ? showCustomLabel
            ? currentItem?.customLabel || ''
            : currentItem?.label || ''
          : placeholder}
        <div className={styles.caret}>
          <Icon name="sort-arrow" width={9} height={9} />
        </div>
      </div>
      <div
        className={classNames(styles.menuWrapper, {
          [styles.toogleRight]: isToggleRight,
          [styles.limitHeightItems]: limitHeightItems,
        })}
        style={{ display: show ? 'block' : 'none' }}
      >
        <div className={styles.items}>
          {title && <div className={styles.title}>{title}</div>}
          {items.map((item, index) => (
            <div
              className={classNames(styles.item, 'item')}
              onClick={() => {
                onChangeValue(item.value);
                setShow(false);
              }}
              key={`item-${index}`}
            >
              <span className={styles.check}>
                {item.value === currentValue && (
                  <Icon name="checked" width={12} height={12} />
                )}
              </span>
              <span className={styles.label}>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
