import dayjs from 'lib/dayjs';
import { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
registerLocale('ja', ja);
import styles from './DateInput.module.scss';
import classNames from 'classnames';
import Icon from '../Icon';

const DateInput = ({
  date,
  setDate,
  showTimeSelect = false,
  customDateFormat,
  maxDate,
  onlyMonthYear = false,
  dateNullText = '',
  resetSupport = false,
}: {
  date: Date;
  setDate: (date: Date) => void;
  showTimeSelect: boolean;
  customDateFormat?: string;
  maxDate?: Date;
  onlyMonthYear?: boolean;
  dateNullText?: string;
  resetSupport?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className={classNames(styles.timeInputWrapper, {
        [styles.withTime]: showTimeSelect,
      })}
      onClick={() => setOpen(true)}
    >
      <input
        className={styles.tableTimeInput}
        type="text"
        value={
          date
            ? dayjs(date).format(customDateFormat ?? 'YYYY/MM/DD')
            : dateNullText
        }
        readOnly
      />
      {resetSupport && (
        <div className={styles.close}>
          <Icon
            name="close"
            width={14}
            height={14}
            onClick={() => {
              setDate(null);
            }}
          />
        </div>
      )}
      {open && (
        <div className={styles.datePicker}>
          <DatePicker
            selected={date ? date : new Date()}
            onChange={(date) => setDate(date)}
            onYearChange={(date) => setDate(date)}
            onMonthChange={(date) => setDate(date)}
            locale="ja"
            showTimeSelect={showTimeSelect}
            onClickOutside={() => setOpen(false)}
            inline
            maxDate={maxDate ?? new Date()}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            showMonthYearPicker={onlyMonthYear}
          />
        </div>
      )}
    </div>
  );
};

export default DateInput;
