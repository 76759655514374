import styles from './HorizontalCourseItemView.module.scss';
import { CourseInfo } from 'service/course';
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
// import CourseRatingView from 'components/common/CourseRatingView';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { track } from '@amplitude/analytics-browser';

function HorizontalCourseItemView({
  course,
  size = 'small',
}: {
  course: CourseInfo;
  size: 'big' | 'medium' | 'small';
}): JSX.Element {
  const router = useRouter();
  if (!course) return <></>;
  const onCourseClick = () => {
    void router.push(`/courses/${course.id}`);
    track('View Course', {
      course_id: course.id?.toString(),
      course_name: course.title,
    });
  };

  return (
    <div className={classNames(styles.courseItemView, styles[size])}>
      <div className={styles.thumbnailWrapper}>
        {course.thumbnail_image_media?.url && (
          <Link href={`/courses/${course.id}`}>
            <a
              style={{ display: 'flex' }}
              onClick={() => {
                track('View Course', {
                  course_id: course.id?.toString(),
                  course_name: course.title,
                });
              }}
            >
              <Image
                src={course.thumbnail_image_media.url}
                height={course.thumbnail_image_media?.height}
                width={course.thumbnail_image_media?.width}
                alt={course.title}
              />
            </a>
          </Link>
        )}
      </div>
      <div className={styles.courseInfo} onClick={onCourseClick}>
        <div className={styles.courseInfoBody}>
          <div className={styles.courseTitle}>{course.title}</div>
          {/* <div className={styles.ratingAndCategory}>
            <CourseRatingView
              count={course.review_summary?.count}
              rating={course.review_summary?.rating}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

const MemoizedHorizontalCourseItemView = React.memo(HorizontalCourseItemView);
export default MemoizedHorizontalCourseItemView;
