import { Switch } from '@material-ui/core';
import styles from './CustomSwitch.module.scss';

const CustomSwitch = ({
  enable,
  handleChange,
  titleOn,
  titleOff,
  id,
  disabled = false,
}: {
  enable: boolean;
  handleChange: (enable: boolean) => void;
  titleOn: string;
  titleOff: string;
  id: string;
  disabled?: boolean;
}) => {
  return (
    <div className={styles.customSwitch}>
      <div className={styles.switch}>
        <span className={styles.flag}>{enable ? titleOn : titleOff}</span>
        <Switch
          focusVisibleClassName={styles.focusVisible}
          disableRipple
          classes={{
            root: styles.root,
            switchBase: styles.switchBase,
            thumb: styles.thumb,
            track: styles.track,
            checked: styles.checked,
          }}
          disabled={disabled}
          checked={enable}
          onChange={() => handleChange(!enable)}
          name={id}
          id={id}
        />
      </div>
    </div>
  );
};

export default CustomSwitch;
