import { clone, isNumber } from 'common/utils';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import { useContext, useEffect, useState } from 'react';
import { ProfileSkillType } from 'service/user';
import { InputProfileSectionContext } from '..';
import {
  BLANK_TEXT,
  InputInfoSubGroup,
  MAX_ONE_LINE_TEXT_LEN,
} from '../Content';
import styles from '../InputProfileSection.module.scss';

// interface SkillGroupType {
//   skill_type: string;
//   skills: ProfileSkillType[];
// }

// const convertSkillsToGroups = (skills: ProfileSkillType[]) => {
//   const types = skills
//     .map((ski) => ski.skill_type)
//     .filter((value, index, array) => array.indexOf(value) === index);
//   return types.map((type) => {
//     return {
//       skill_type: type,
//       skills: skills.filter((sk) => sk.skill_type === type),
//     };
//   });
// };

// const convertGroupsToSkills = (groups: SkillGroupType[]) => {
//   let skis: ProfileSkillType[] = [];
//   groups.forEach((group) => {
//     const newSkills = (group.skills || []).map((sk) => {
//       sk.skill_type = group.skill_type?.trim();
//       return sk;
//     });
//     skis = skis.concat(newSkills);
//   });
//   return skis.filter(
//     (sk) =>
//       sk.skill_type &&
//       sk.name &&
//       sk.exp_period_year >= 0 &&
//       sk.exp_period_month >= 0 &&
//       sk.exp_period_year + sk.exp_period_month > 0
//   );
// };

export const InputSkills = () => {
  const { fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const [isEditMode, setEditMode] = useState(false);
  const [skills, setSkills] = useState<ProfileSkillType[]>(
    fetchedUserInfo?.profile_skills || []
  );
  useEffect(() => {
    if (!isEditMode) {
      setSkills(fetchedUserInfo?.profile_skills || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.profile_skills]);

  // const addBlankSkillGroup = () => {
  //   const newSkillGroup = clone(skillGroups) as SkillGroupType[];
  //   newSkillGroup.push({
  //     skill_type: '',
  //     skills: [{ name: '', exp_period_year: 1, exp_period_month: 0 }],
  //   });
  //   setSkillGroups(newSkillGroup);
  // };

  // const deleteSkillGroup = (index: number) => {
  //   const newSkillGroup = clone(skillGroups) as SkillGroupType[];
  //   newSkillGroup.splice(index, 1);
  //   setSkillGroups(newSkillGroup);
  // };

  // const changeSkillGroupName = (index: number, newName: string) => {
  //   const newSkillGroup = clone(skillGroups) as SkillGroupType[];
  //   if (!newSkillGroup[index]) return;
  //   newSkillGroup[index].skill_type = newName;
  //   setSkillGroups(newSkillGroup);
  // };

  const addBlankSubSkill = () => {
    const newSkills = clone(skills) as ProfileSkillType[];
    newSkills.push({
      name: '',
      exp_period_year: 1,
      exp_period_month: 0,
    });
    setSkills(newSkills);
  };

  const deleteSubSkill = (subSkillIndex: number) => {
    const newSkills = clone(skills) as ProfileSkillType[];
    if (!newSkills[subSkillIndex]) return;
    newSkills.splice(subSkillIndex, 1);
    setSkills(newSkills);
  };

  const changeSubSkillInfo = ({
    subSkillIndex,
    attrName,
    val,
  }: {
    subSkillIndex: number;
    attrName: string;
    val: string | number;
  }) => {
    const newSkills = clone(skills) as ProfileSkillType[];
    if (!newSkills[subSkillIndex]) return;
    newSkills[subSkillIndex][attrName] = val;
    setSkills(newSkills);
  };

  useEffect(() => {
    if (isEditMode && skills.length === 0) {
      addBlankSubSkill();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  return (
    <InputInfoSubGroup
      name="経験・スキル"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        const newSkills = skills.filter(
          (sk) =>
            sk.name &&
            sk.exp_period_year >= 0 &&
            sk.exp_period_month >= 0 &&
            sk.exp_period_year + sk.exp_period_month > 0
        );
        setSkills(newSkills);
        updateUserInfo({
          profile_skills: newSkills,
        });
      }}
      onCancel={() => {
        setSkills(fetchedUserInfo?.profile_skills || []);
      }}
    >
      {isEditMode ? (
        <div className={styles.skillsEditMode}>
          {(skills || []).map((ski, skIndex) => {
            return (
              <div className={styles.subItem} key={`skg-${skIndex}`}>
                <div className={styles.subItemName}>
                  <span className={styles.subItemIndex}>{`${
                    skIndex + 1
                  }.`}</span>
                  <input
                    type="text"
                    value={ski.name}
                    maxLength={MAX_ONE_LINE_TEXT_LEN}
                    className={styles.inputTextContentOneLine}
                    style={{ width: '150px' }}
                    onChange={(e) =>
                      changeSubSkillInfo({
                        subSkillIndex: skIndex,
                        attrName: 'name',
                        val: e.target.value,
                      })
                    }
                    placeholder="スキル"
                  />
                </div>
                <div className={styles.subItemYear}>
                  <input
                    type="number"
                    value={ski.exp_period_year}
                    min={0}
                    max={100}
                    style={{ width: '60px', marginRight: '5px' }}
                    className={styles.inputTextContentOneLine}
                    onChange={(e) => {
                      let newVal = parseInt(e.target.value);
                      if (isNumber(newVal)) {
                        newVal = Math.min(100, Math.max(newVal, 0));
                      } else {
                        newVal = 0;
                      }
                      changeSubSkillInfo({
                        subSkillIndex: skIndex,
                        attrName: 'exp_period_year',
                        val: newVal,
                      });
                    }}
                  />
                  年
                </div>
                <div className={styles.subItemMonth}>
                  <input
                    type="number"
                    min={0}
                    max={11}
                    value={ski.exp_period_month}
                    style={{ width: '60px', marginRight: '5px' }}
                    className={styles.inputTextContentOneLine}
                    onChange={(e) => {
                      let newVal = parseInt(e.target.value);
                      if (isNumber(newVal)) {
                        newVal = Math.min(11, Math.max(newVal, 0));
                      } else {
                        newVal = 0;
                      }
                      changeSubSkillInfo({
                        subSkillIndex: skIndex,
                        attrName: 'exp_period_month',
                        val: newVal,
                      });
                    }}
                  />
                  カ月
                </div>
                <Icon
                  name="delete-trash"
                  height={24}
                  width={24}
                  onClick={() => deleteSubSkill(skIndex)}
                />
              </div>
            );
          })}
          <div className={styles.addSkill}>
            <Button text="スキルを追加" onClick={() => addBlankSubSkill()} />
          </div>
        </div>
      ) : (
        <div className={styles.skills}>
          {(!skills || skills.length === 0) && (
            <div className={styles.infoShow}>{BLANK_TEXT}</div>
          )}
          {(skills || []).map((ski, skIndex) => {
            return (
              <div className={styles.subItem} key={`skill-${skIndex}`}>
                <span className={styles.subItemName}>
                  {ski.name || BLANK_TEXT}
                </span>
                <span className={styles.subItemTime}>
                  {ski.exp_period_year > 0 && `${ski.exp_period_year}年`}
                  {ski.exp_period_month > 0 && `${ski.exp_period_month}カ月`}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </InputInfoSubGroup>
  );
};
