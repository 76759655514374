import classNames from 'classnames';
import CustomSwitch from 'components/atoms/CustomSwitch';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import DropdownWithSearch from 'components/common/DropdownWithSearch';
import HelpPopover from 'components/common/HelpPopover';
import Icon from 'components/common/Icon';
import dayjs from 'lib/dayjs';
import { useContext, useMemo, useState, useEffect } from 'react';
import { InputProfileSectionContext } from '..';
import { InputLanguages } from '../InputLanguages';
import styles from '../InputProfileSection.module.scss';
import { InputSkills } from '../InputSkills';
import { InputStudyExperiences } from '../InputStudyExperiences';
import { InputWorkExperiences } from '../InputWorkExperiences';
import InputWithLabel from 'components/common/InputWithLabelV2';
import DateInput from 'components/common/DateInput';
import InputAddress from '../InputAddress';
import InputName from '../InputName';
import { validEmail } from 'common/utils';
import { track } from '@amplitude/analytics-browser';

export const MAX_TEXT_LEN = 2000;
export const MAX_ONE_LINE_TEXT_LEN = 100;
export const BLANK_TEXT = '未入力';
export const maxLengthError = (maxLength: number) => {
  return `${maxLength}文字以内で入力してください`;
};

export const InputInfoGroup = ({
  id,
  tooltipContent,
  name,
  children,
  disablePublic = false,
  isPublic = false,
  setIsPublic,
}: {
  id: string;
  tooltipContent: JSX.Element | string;
  name: string;
  children: React.ReactChild | React.ReactChild[];
  disablePublic?: boolean;
  isPublic?: boolean;
  setIsPublic?: (p: boolean) => void;
}) => {
  const { onlyViewMode, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  if (onlyViewMode && !isPublic) return <></>;
  return (
    <div className={styles.inputInfoGroup}>
      <div className={styles.groupHeader}>
        <div className={styles.groupName}>{name}</div>
        {!onlyViewMode && (
          <>
            {!disablePublic ? (
              <div className={styles.rightSide}>
                <HelpPopover
                  tooltipClassname={styles.tooltip}
                  tooltipWidth={220}
                  content={<>{tooltipContent}</>}
                  className={styles.publicHelpIcon}
                />
                <CustomSwitch
                  enable={isPublic}
                  handleChange={(publicS: boolean) => {
                    if (disablePublic) return;
                    updateUserInfo({ [id]: publicS });
                    setIsPublic(publicS);
                  }}
                  titleOff="公開"
                  titleOn="公開"
                  id={`${id}-publicSetting`}
                />
              </div>
            ) : (
              <span className={styles.warningText}>
                ご本人以外には公開されません
              </span>
            )}
          </>
        )}
      </div>
      {children}
    </div>
  );
};

export const InputInfoSubGroup = ({
  name,
  children,
  isEditMode,
  setEditMode,
  onCancel,
  onSave,
  disableSave = false,
  highlightName = false,
  errorText = '',
}: {
  name: string;
  children: React.ReactChild | React.ReactChild[];
  isEditMode: boolean;
  setEditMode: (isEdit: boolean) => void;
  onCancel: () => void;
  onSave: () => void;
  disableSave?: boolean;
  highlightName?: boolean;
  errorText?: string;
}) => {
  const { onlyViewMode, isFetchingData, isUpdating, setEditingProfileCount } =
    useContext(InputProfileSectionContext);
  return (
    <div
      className={classNames(styles.inputInfoSubGroup, {
        [styles.editMode]: isEditMode,
      })}
    >
      <div className={styles.subGroupHeader}>
        <div
          className={classNames(styles.subGroupName, {
            [styles.highlightName]: highlightName,
          })}
        >
          {name}
        </div>
        {!onlyViewMode && (
          <>
            {isEditMode ? (
              <div className={styles.rightSide}>
                <Button
                  text="キャンセル"
                  blackAndWhite
                  onClick={() => {
                    setEditMode && setEditMode(false);
                    setEditingProfileCount((count) => {
                      return count - 1;
                    });
                    onCancel && onCancel();
                  }}
                />
                <Button
                  text="保存"
                  disabled={!!disableSave || isUpdating || isFetchingData}
                  onClick={() => {
                    setEditMode && setEditMode(false);
                    setEditingProfileCount((count) => {
                      return count - 1;
                    });
                    track('Complete Enter Profile', {
                      profile_item_name: name,
                    });
                    onSave && onSave();
                  }}
                />
                {errorText && (
                  <span className={styles.errorMessenge}>{errorText}</span>
                )}
              </div>
            ) : (
              <div
                className={styles.rightSide}
                style={
                  isUpdating || isFetchingData
                    ? { opacity: 0.5, pointerEvents: 'none' }
                    : {}
                }
              >
                <Icon
                  name="edit-black"
                  height={22}
                  width={22}
                  onClick={() => {
                    if (isUpdating || isFetchingData) return;
                    setEditMode && setEditMode(true);
                    setEditingProfileCount((count) => {
                      return count + 1;
                    });
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
      {children}
    </div>
  );
};

export const InputIntro = () => {
  const { fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const [isEditMode, setEditMode] = useState(false);
  const [introText, setIntroText] = useState(
    fetchedUserInfo?.self_introduction || ''
  );

  useEffect(() => {
    if (!isEditMode) {
      setIntroText(fetchedUserInfo?.self_introduction || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.self_introduction]);

  const hasErrorMaxLength = introText.length > MAX_TEXT_LEN;
  return (
    <InputInfoSubGroup
      name="自己紹介"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({ self_introduction: introText });
      }}
      onCancel={() => {
        setIntroText(fetchedUserInfo?.self_introduction || '');
      }}
      disableSave={hasErrorMaxLength}
    >
      {isEditMode ? (
        <InputWithLabel
          title={`${introText.length}/${MAX_TEXT_LEN}`}
          error={hasErrorMaxLength ? maxLengthError(MAX_TEXT_LEN) : ''}
        >
          <textarea
            className={styles.inputTextContent}
            rows={5}
            placeholder="例：PostPrimeの人材紹介立ち上げをしています。
            日本の雇用をもっと自由に選択できる世界を目指しています。
            その他に人事関連や営業関連について活動を行ったり、学んだりもしています。"
            value={introText}
            maxLength={MAX_TEXT_LEN}
            onChange={(e) => setIntroText(e.target.value)}
          />
        </InputWithLabel>
      ) : introText ? (
        <div className={styles.textContent}>{introText}</div>
      ) : (
        <span className={styles.infoShow}>{BLANK_TEXT}</span>
      )}
    </InputInfoSubGroup>
  );
};

// export const InputActivity = () => {
//   const { fetchedUserInfo } = useContext(InputProfileSectionContext);
//   const [isEditMode, setEditMode] = useState(false);
//   const [activityText, setActivityText] = useState(
//     fetchedUserInfo?.activity || ''
//   );
//   useEffect(() => {
//     if (!isEditMode) {
//       setActivityText(fetchedUserInfo?.activity || '');
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [fetchedUserInfo?.activity]);
//   const hasErrorMaxLength = activityText.length > MAX_TEXT_LEN;
//   return (
//     <InputInfoSubGroup
//       name="活動・コミュニティ"
//       isEditMode={isEditMode}
//       setEditMode={setEditMode}
//       disableSave={hasErrorMaxLength}
//     >
//       {isEditMode ? (
//         <InputWithLabel
//           title={`${activityText.length}/${MAX_TEXT_LEN}`}
//           error={hasErrorMaxLength ? maxLengthError(MAX_TEXT_LEN) : ''}
//         >
//           <textarea
//             className={styles.inputTextContent}
//             rows={5}
//             placeholder="活動・コミュニティを記入してください"
//             value={activityText}
//             onChange={(e) => setActivityText(e.target.value)}
//           />
//         </InputWithLabel>
//       ) : (
//         <div className={styles.textContent}>{activityText || BLANK_TEXT}</div>
//       )}
//     </InputInfoSubGroup>
//   );
// };

export const InputPhoneNumber = () => {
  const { fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const [isEditMode, setEditMode] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    fetchedUserInfo?.phone_number || ''
  );
  useEffect(() => {
    if (!isEditMode) {
      setPhoneNumber(fetchedUserInfo?.phone_number || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.phone_number]);

  return (
    <InputInfoSubGroup
      name="電話番号"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({ phone_number: phoneNumber });
      }}
      onCancel={() => {
        setPhoneNumber(fetchedUserInfo?.phone_number || '');
      }}
    >
      {isEditMode ? (
        <InputWithLabel
          title={`${phoneNumber.length}/${MAX_ONE_LINE_TEXT_LEN}`}
        >
          <input
            className={styles.inputTextContentOneLine}
            placeholder="例：080 8888 8888"
            value={phoneNumber}
            name="phone_number"
            maxLength={MAX_ONE_LINE_TEXT_LEN}
            onChange={(e) => {
              const newVal = e.target.value;
              // if (newVal.length > MAX_ONE_LINE_TEXT_LEN) return;
              setPhoneNumber(newVal);
            }}
          />
        </InputWithLabel>
      ) : (
        <div className={styles.infoShow}>
          {phoneNumber
            ? `********${phoneNumber.substring(phoneNumber.length - 4)}`
            : BLANK_TEXT}
        </div>
      )}
    </InputInfoSubGroup>
  );
};

export const InputContactEmail = () => {
  const { fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const [isEditMode, setEditMode] = useState(false);
  const [contactEmail, setContactEmail] = useState(
    fetchedUserInfo?.email || ''
  );
  const hasErrorEmail = contactEmail && !validEmail(contactEmail);
  useEffect(() => {
    if (!isEditMode) {
      setContactEmail(fetchedUserInfo?.email || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.email]);

  return (
    <InputInfoSubGroup
      name="連絡メールアドレス"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      disableSave={hasErrorEmail}
      onSave={() => {
        updateUserInfo({ email: contactEmail });
      }}
      onCancel={() => {
        setContactEmail(fetchedUserInfo?.email || '');
      }}
    >
      {isEditMode ? (
        <InputWithLabel
          title={`${contactEmail.length}/${MAX_ONE_LINE_TEXT_LEN}`}
          error={hasErrorEmail ? '正しいメールアドレスをご入力ください' : ''}
        >
          <input
            className={styles.inputTextContentOneLine}
            placeholder="連絡メールアドレスを入力する"
            maxLength={MAX_ONE_LINE_TEXT_LEN}
            value={contactEmail}
            type="email"
            name="email"
            onChange={(e) => {
              const newVal = e.target.value
                .replace(/ /g, '')
                .replace(/\s/g, '');
              // if (newVal.length > MAX_ONE_LINE_TEXT_LEN) return;
              setContactEmail(newVal);
            }}
          />
        </InputWithLabel>
      ) : (
        <div className={styles.infoShow}>{contactEmail || BLANK_TEXT}</div>
      )}
    </InputInfoSubGroup>
  );
};

export const InputHobbie = () => {
  const { fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const [isEditMode, setEditMode] = useState(false);
  const [hobbieText, setHobbieText] = useState(fetchedUserInfo?.interest || '');
  const hasErrorMaxLength = hobbieText.length > MAX_TEXT_LEN;
  useEffect(() => {
    if (!isEditMode) {
      setHobbieText(fetchedUserInfo?.interest || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.interest]);

  return (
    <InputInfoSubGroup
      name="興味・希望"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      disableSave={hasErrorMaxLength}
      onSave={() => {
        updateUserInfo({ interest: hobbieText });
      }}
      onCancel={() => {
        setHobbieText(fetchedUserInfo?.interest || '');
      }}
    >
      {isEditMode ? (
        <InputWithLabel
          title={`${hobbieText.length}/${MAX_TEXT_LEN}`}
          error={hasErrorMaxLength ? maxLengthError(MAX_TEXT_LEN) : ''}
        >
          <textarea
            className={styles.inputTextContent}
            rows={5}
            placeholder="例：社会にとって良いと思うサービスの支援
            これまでの経験を活かし、携わるサービスや企業の成功支援することに取り組みたいです。
            事業に関する知見やスキルを深めていく
            自身の課題として事業理解がまだまだ乏しいことが挙げられると考えております。
            今後は上記経験を伸ばし、影響を高めていきたいです。"
            value={hobbieText}
            maxLength={MAX_TEXT_LEN}
            onChange={(e) => setHobbieText(e.target.value)}
          />
        </InputWithLabel>
      ) : hobbieText ? (
        <div className={styles.textContent}>{hobbieText}</div>
      ) : (
        <span className={styles.infoShow}>{BLANK_TEXT}</span>
      )}
    </InputInfoSubGroup>
  );
};

export const InputIncome = () => {
  const [isEditMode, setEditMode] = useState(false);
  const { salaryRanges, fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const options = useMemo(() => {
    return salaryRanges.map((range) => {
      return {
        label: range.name,
        value: range.id,
      };
    });
  }, [salaryRanges]);
  const [currentSalaryId, setCurrentSalaryId] = useState(
    fetchedUserInfo?.current_salary_range?.id
  );
  useEffect(() => {
    if (!isEditMode) {
      setCurrentSalaryId(fetchedUserInfo?.current_salary_range?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.current_salary_range?.id]);
  return (
    <InputInfoSubGroup
      name="現在の年収"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({ current_salary_range_id: currentSalaryId || 0 });
      }}
      onCancel={() => {
        setCurrentSalaryId(fetchedUserInfo?.current_salary_range?.id);
      }}
    >
      {isEditMode ? (
        <DropdownWithSearch
          items={options}
          placeholder="現在の年収"
          limitHeightItems
          className={styles.customDropdown}
          currentValue={currentSalaryId}
          title=""
          onChangeValue={(val: number) => setCurrentSalaryId(val)}
        />
      ) : (
        <span className={styles.infoShow}>
          {options.find((op) => op.value === currentSalaryId)?.label ||
            BLANK_TEXT}
        </span>
      )}
    </InputInfoSubGroup>
  );
};

export const InputDesiredIncome = () => {
  const { salaryRanges, fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const options = useMemo(() => {
    return salaryRanges.map((range) => {
      return {
        label: range.name,
        value: range.id,
      };
    });
  }, [salaryRanges]);
  const [isEditMode, setEditMode] = useState(false);
  const [desiredSalaryId, setDesiredSalaryId] = useState(
    fetchedUserInfo?.desired_salary_range?.id
  );
  useEffect(() => {
    if (!isEditMode) {
      setDesiredSalaryId(fetchedUserInfo?.desired_salary_range?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.desired_salary_range?.id]);

  return (
    <InputInfoSubGroup
      name="希望の年収"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({ desired_salary_range_id: desiredSalaryId || 0 });
      }}
      onCancel={() => {
        setDesiredSalaryId(fetchedUserInfo?.desired_salary_range?.id);
      }}
    >
      {isEditMode ? (
        <DropdownWithSearch
          items={options}
          placeholder="希望の年収"
          limitHeightItems
          className={styles.customDropdown}
          currentValue={desiredSalaryId}
          title=""
          onChangeValue={(val: number) => setDesiredSalaryId(val)}
        />
      ) : (
        <span className={styles.infoShow}>
          {options.find((op) => op.value === desiredSalaryId)?.label ||
            BLANK_TEXT}
        </span>
      )}
    </InputInfoSubGroup>
  );
};

export const InputCountry = () => {
  const { nationalities, fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const options = useMemo(() => {
    return nationalities.map((nation) => {
      return {
        label: nation.name,
        value: nation.id,
      };
    });
  }, [nationalities]);

  const [isEditMode, setEditMode] = useState(false);
  const [countryId, setCountryId] = useState(fetchedUserInfo?.nationality?.id);
  useEffect(() => {
    if (!isEditMode) {
      setCountryId(fetchedUserInfo?.nationality?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.nationality?.id]);

  return (
    <InputInfoSubGroup
      name="国籍"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({ nationality_id: countryId || 0 });
      }}
      onCancel={() => {
        setCountryId(fetchedUserInfo?.nationality?.id);
      }}
    >
      {isEditMode ? (
        <DropdownWithSearch
          items={options}
          placeholder="国籍を検索する"
          limitHeightItems
          className={styles.customDropdown}
          currentValue={countryId}
          title=""
          onChangeValue={(val: number) => setCountryId(val)}
        />
      ) : (
        <span className={styles.infoShow}>
          {fetchedUserInfo?.nationality?.name || BLANK_TEXT}
        </span>
      )}
    </InputInfoSubGroup>
  );
};

export const InputDateOfBirth = () => {
  const [isEditMode, setEditMode] = useState(false);
  const { fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const [birthDay, setBirthDay] = useState(
    fetchedUserInfo?.birthday ? new Date(fetchedUserInfo?.birthday) : null
  );
  useEffect(() => {
    if (!isEditMode) {
      setBirthDay(
        fetchedUserInfo?.birthday ? new Date(fetchedUserInfo?.birthday) : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.birthday]);

  return (
    <InputInfoSubGroup
      name="生年月日"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({
          birthday: birthDay ? dayjs(birthDay).format('YYYY-MM-DD') : '',
        });
      }}
      onCancel={() => {
        setBirthDay(
          fetchedUserInfo?.birthday ? new Date(fetchedUserInfo?.birthday) : null
        );
      }}
    >
      {isEditMode ? (
        <InputWithLabel style={{ width: '200px' }}>
          <DateInput
            date={birthDay}
            showTimeSelect={false}
            setDate={(date) => setBirthDay(date)}
            dateNullText="生年月日を入力する"
            resetSupport
          />
        </InputWithLabel>
      ) : (
        <span className={styles.infoShow}>
          {birthDay ? dayjs(birthDay).format('YYYY/MM/DD') : BLANK_TEXT}
        </span>
      )}
    </InputInfoSubGroup>
  );
};

export const InputGender = () => {
  const { fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const options = useMemo(() => {
    return [
      {
        value: 0,
        label: '未回答',
      },
      {
        value: 1,
        label: '男性',
      },
      {
        value: 2,
        label: '女性',
      },
    ];
  }, []);

  const [isEditMode, setEditMode] = useState(false);
  const [gender, setGender] = useState(fetchedUserInfo?.gender || 0);
  useEffect(() => {
    if (!isEditMode) {
      setGender(fetchedUserInfo?.gender);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.gender]);

  return (
    <InputInfoSubGroup
      name="性別"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({ gender: gender });
      }}
      onCancel={() => {
        setGender(fetchedUserInfo?.gender);
      }}
    >
      {isEditMode ? (
        <Dropdown
          items={options}
          placeholder="性別"
          limitHeightItems
          className={styles.customDropdown}
          currentValue={gender}
          title=""
          onChangeValue={(val: number) => setGender(val)}
        />
      ) : (
        <span className={styles.infoShow}>
          {options.find((op) => op.value === gender)?.label || '未回答'}
        </span>
      )}
    </InputInfoSubGroup>
  );
};

export const ProfileSection = () => {
  const { fetchedUserInfo } = useContext(InputProfileSectionContext);
  const [isPublic, setIsPublic] = useState(
    fetchedUserInfo?.is_profile_public || false
  );
  useEffect(() => {
    setIsPublic(fetchedUserInfo?.is_profile_public || false);
  }, [fetchedUserInfo?.is_profile_public]);
  return (
    <InputInfoGroup
      id="is_profile_public"
      name="プロフィール"
      tooltipContent={
        <div>
          公開してからご本人以外の方からも
          <br /> 見られるようになります。
        </div>
      }
      isPublic={isPublic}
      setIsPublic={setIsPublic}
    >
      <InputIntro />
      <InputLanguages />
      <InputSkills />
      {/* <InputActivity /> */}
      <InputHobbie />
    </InputInfoGroup>
  );
};

export const BasicInfoSection = () => {
  const { fetchedUserInfo } = useContext(InputProfileSectionContext);
  const [isPublic, setIsPublic] = useState(
    fetchedUserInfo?.is_basic_information_public || false
  );

  useEffect(() => {
    setIsPublic(fetchedUserInfo?.is_basic_information_public || false);
  }, [fetchedUserInfo?.is_basic_information_public]);
  return (
    <InputInfoGroup
      id="is_basic_information_public"
      name="基本情報"
      tooltipContent={
        <div>
          公開してからご本人以外の方からも
          <br /> 見られるようになります。
        </div>
      }
      isPublic={isPublic}
      setIsPublic={setIsPublic}
    >
      <InputName />
      <InputCountry />
      <InputDateOfBirth />
      <InputGender />
    </InputInfoGroup>
  );
};

export const InputIndustryType = () => {
  const [isEditMode, setEditMode] = useState(false);
  const { industryTypes, updateUserInfo, fetchedUserInfo } = useContext(
    InputProfileSectionContext
  );
  const options = useMemo(() => {
    return industryTypes.map((type) => {
      return {
        label: type.name,
        value: type.id,
      };
    });
  }, [industryTypes]);
  const [currentTypeId, setCurrentTypeId] = useState(
    fetchedUserInfo?.industry?.id
  );
  useEffect(() => {
    if (!isEditMode) {
      setCurrentTypeId(fetchedUserInfo?.industry?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.industry?.id]);
  return (
    <InputInfoSubGroup
      name="業界"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({ industry_type_id: currentTypeId || 0 });
      }}
      onCancel={() => {
        setCurrentTypeId(fetchedUserInfo?.industry?.id);
      }}
    >
      {isEditMode ? (
        <DropdownWithSearch
          items={options}
          placeholder="業界を検索する"
          limitHeightItems
          className={styles.customDropdown}
          currentValue={currentTypeId}
          title=""
          onChangeValue={(val: number) => setCurrentTypeId(val)}
        />
      ) : (
        <span className={styles.infoShow}>
          {options.find((op) => op.value === currentTypeId)?.label ||
            BLANK_TEXT}
        </span>
      )}
    </InputInfoSubGroup>
  );
};

export const InputDesiredIndustryType = () => {
  const [isEditMode, setEditMode] = useState(false);
  const { industryTypes, updateUserInfo, fetchedUserInfo } = useContext(
    InputProfileSectionContext
  );
  const options = useMemo(() => {
    return industryTypes.map((type) => {
      return {
        label: type.name,
        value: type.id,
      };
    });
  }, [industryTypes]);
  const [currentTypeId, setCurrentTypeId] = useState(
    fetchedUserInfo?.desired_industry?.id
  );
  useEffect(() => {
    if (!isEditMode) {
      setCurrentTypeId(fetchedUserInfo?.desired_industry?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.desired_industry?.id]);
  return (
    <InputInfoSubGroup
      name="希望の業界"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({ desired_industry_type_id: currentTypeId || 0 });
      }}
      onCancel={() => {
        setCurrentTypeId(fetchedUserInfo?.desired_industry?.id);
      }}
    >
      {isEditMode ? (
        <DropdownWithSearch
          items={options}
          placeholder="業界を検索する"
          limitHeightItems
          className={styles.customDropdown}
          currentValue={currentTypeId}
          title=""
          onChangeValue={(val: number) => setCurrentTypeId(val)}
        />
      ) : (
        <span className={styles.infoShow}>
          {options.find((op) => op.value === currentTypeId)?.label ||
            BLANK_TEXT}
        </span>
      )}
    </InputInfoSubGroup>
  );
};

export const InputOccupation = () => {
  const [isEditMode, setEditMode] = useState(false);
  const { occupations, fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const options = useMemo(() => {
    return occupations.map((opa) => {
      return {
        label: opa.name,
        value: opa.id,
      };
    });
  }, [occupations]);
  const [currentOccupationId, setCurrentOccupationId] = useState(
    fetchedUserInfo?.occupation?.id
  );
  useEffect(() => {
    if (!isEditMode) {
      setCurrentOccupationId(fetchedUserInfo?.occupation?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.occupation?.id]);
  return (
    <InputInfoSubGroup
      name="職種"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({ occupation_id: currentOccupationId || 0 });
      }}
      onCancel={() => {
        setCurrentOccupationId(fetchedUserInfo?.occupation?.id);
      }}
    >
      {isEditMode ? (
        <DropdownWithSearch
          items={options}
          placeholder="職種を検索する"
          limitHeightItems
          className={styles.customDropdown}
          currentValue={currentOccupationId}
          title=""
          onChangeValue={(val: number) => setCurrentOccupationId(val)}
        />
      ) : (
        <span className={styles.infoShow}>
          {options.find((op) => op.value === currentOccupationId)?.label ||
            BLANK_TEXT}
        </span>
      )}
    </InputInfoSubGroup>
  );
};

export const InputChangeJobTime = () => {
  const [isEditMode, setEditMode] = useState(false);
  const { timeChangeJobs, fetchedUserInfo, updateUserInfo, changeJobRef } =
    useContext(InputProfileSectionContext);
  const options = useMemo(() => {
    return timeChangeJobs.map((time) => {
      return {
        label: time.name,
        value: time.id,
      };
    });
  }, [timeChangeJobs]);
  const [timeChangeId, setTimeChangeId] = useState(
    fetchedUserInfo?.desired_time_change_job?.id || ''
  );
  useEffect(() => {
    if (!isEditMode) {
      setTimeChangeId(fetchedUserInfo?.desired_time_change_job?.id || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.desired_time_change_job?.id]);
  return (
    <InputInfoSubGroup
      name="転職希望"
      isEditMode={isEditMode}
      highlightName={!timeChangeId}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({ desired_time_change_job_id: timeChangeId || '' });
      }}
      onCancel={() => {
        setTimeChangeId(fetchedUserInfo?.desired_time_change_job?.id || '');
      }}
    >
      <div ref={changeJobRef}></div>
      {isEditMode ? (
        <DropdownWithSearch
          items={options}
          placeholder="転職希望時期"
          limitHeightItems
          className={styles.customDropdown}
          currentValue={timeChangeId}
          title=""
          onChangeValue={(val: string) => setTimeChangeId(val)}
        />
      ) : (
        <span className={styles.infoShow}>
          {options.find((op) => op.value === timeChangeId)?.label || BLANK_TEXT}
        </span>
      )}
    </InputInfoSubGroup>
  );
};

export const InputDesiredOccupation = () => {
  const [isEditMode, setEditMode] = useState(false);
  const { occupations, fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const options = useMemo(() => {
    return occupations.map((opa) => {
      return {
        label: opa.name,
        value: opa.id,
      };
    });
  }, [occupations]);
  const [currentOccupationId, setCurrentOccupationId] = useState(
    fetchedUserInfo?.desired_occupation?.id
  );
  useEffect(() => {
    if (!isEditMode) {
      setCurrentOccupationId(fetchedUserInfo?.desired_occupation?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.desired_occupation?.id]);
  return (
    <InputInfoSubGroup
      name="希望の職種"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({ desired_occupation_id: currentOccupationId || 0 });
      }}
      onCancel={() => {
        setCurrentOccupationId(fetchedUserInfo?.desired_occupation?.id);
      }}
    >
      {isEditMode ? (
        <DropdownWithSearch
          items={options}
          placeholder="職種を検索する"
          limitHeightItems
          className={styles.customDropdown}
          currentValue={currentOccupationId}
          title=""
          onChangeValue={(val: number) => setCurrentOccupationId(val)}
        />
      ) : (
        <span className={styles.infoShow}>
          {options.find((op) => op.value === currentOccupationId)?.label ||
            BLANK_TEXT}
        </span>
      )}
    </InputInfoSubGroup>
  );
};

export const WorkExperiencesSection = () => {
  const { fetchedUserInfo } = useContext(InputProfileSectionContext);
  const [isPublic, setIsPublic] = useState(
    fetchedUserInfo?.is_profile_job_public || false
  );
  useEffect(() => {
    setIsPublic(fetchedUserInfo?.is_profile_job_public || false);
  }, [fetchedUserInfo?.is_profile_job_public]);
  return (
    <InputInfoGroup
      id="is_profile_job_public"
      name="職歴"
      tooltipContent={
        <div>
          公開してからご本人以外の方からも
          <br /> 見られるようになります。
        </div>
      }
      isPublic={isPublic}
      setIsPublic={setIsPublic}
    >
      <InputIndustryType />
      <InputOccupation />
      <InputWorkExperiences />
    </InputInfoGroup>
  );
};

export const StudyExperiencesSection = () => {
  const { fetchedUserInfo } = useContext(InputProfileSectionContext);
  const [isPublic, setIsPublic] = useState(
    fetchedUserInfo?.is_education_public || false
  );
  useEffect(() => {
    setIsPublic(fetchedUserInfo?.is_education_public || false);
  }, [fetchedUserInfo?.is_education_public]);
  return (
    <InputInfoGroup
      id="is_education_public"
      name="学歴"
      tooltipContent={
        <div>
          公開してからご本人以外の方からも
          <br /> 見られるようになります。
        </div>
      }
      isPublic={isPublic}
      setIsPublic={setIsPublic}
    >
      <InputStudyExperiences />
    </InputInfoGroup>
  );
};

export const ConditionsInfoSection = () => {
  return (
    <InputInfoGroup
      id="incomeInfoSection"
      name="希望条件"
      tooltipContent={
        <div>
          公開してからご本人以外の方からも
          <br /> 見られるようになります。
        </div>
      }
      disablePublic
    >
      <InputChangeJobTime />
      <InputDesiredIndustryType />
      <InputDesiredOccupation />
      <InputIncome />
      <InputDesiredIncome />
    </InputInfoGroup>
  );
};

export const ContactInfoSection = () => {
  return (
    <InputInfoGroup
      id="contactInfoSectiom"
      name="連絡先"
      tooltipContent={
        <div>
          公開してからご本人以外の方からも
          <br /> 見られるようになります。
        </div>
      }
      disablePublic
    >
      <InputPhoneNumber />
      <InputContactEmail />
      <InputAddress />
    </InputInfoGroup>
  );
};

export const ProgressBarSection = () => {
  const { fetchedUserInfo, changeJobRef } = useContext(
    InputProfileSectionContext
  );

  if (!fetchedUserInfo?.total_fields) return <></>;
  if (
    fetchedUserInfo?.number_of_fields_entered === fetchedUserInfo?.total_fields
  )
    return <></>;
  const percent = Math.floor(
    (fetchedUserInfo?.number_of_fields_entered /
      fetchedUserInfo?.total_fields) *
      100
  );
  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <div className={styles.progressBarSection}>
      <span className={styles.progressTitle}>情報入力の進捗</span>
      <div className={styles.progressInfo}>
        <div className={styles.progressBar}>
          <div
            className={classNames(styles.notYetOverlay, {
              [styles.fullWidth]: percent === 0,
            })}
            style={{
              width: `${100 - percent}%`,
            }}
          ></div>
        </div>
        <span className={styles.number}>({percent}%)</span>
      </div>
      {!fetchedUserInfo?.desired_time_change_job?.id ? (
        <div className={styles.alertText}>
          <strong
            onClick={() => {
              window.scrollTo({
                behavior: 'smooth',
                left: 0,
                top: changeJobRef?.current?.offsetTop - 200,
              });
            }}
          >
            転職希望
          </strong>
          を入力してください
        </div>
      ) : (
        (!fetchedUserInfo?.email || !fetchedUserInfo?.phone_number) && (
          <div className={styles.alertText}>
            <strong onClick={() => scrollToBottom()}>連絡先</strong>
            を入力してください
          </div>
        )
      )}
    </div>
  );
};
