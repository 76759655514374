import { CSSProperties, ReactChild, ReactElement, ReactNode } from 'react';
import cn from 'classnames';
import styles from './InputWithLabel.module.scss';
// import classNames from 'classnames';

const InputWithLabelV2 = ({
  title,
  children,
  error = '',
  className,
  style = {},
  required = false,
  requiredAlert = false,
}: {
  style?: CSSProperties;
  title?: string | ReactElement;
  error?: string;
  className?: string;
  children: ReactChild | ReactChild[] | ReactNode | ReactNode[];
  required?: boolean;
  requiredAlert?: boolean;
}) => {
  return (
    <div className={styles.inputGroup} style={style}>
      {title && (
        <div className={styles.header}>
          {title}
          {required && <span className={styles.requiredFlag}>*</span>}
        </div>
      )}
      <div
        className={cn(styles.value, {
          [className]: !!className,
          [styles.requiredAlert]: requiredAlert,
        })}
      >
        {children}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default InputWithLabelV2;
