import classNames from 'classnames';
import { useOutsideClick } from 'common/utils';
import { FC, useMemo, useRef, useState } from 'react';
import Icon from 'components/common/Icon';
import styles from './Dropdown.module.scss';

interface MenuItemType {
  label: string;
  value: string | number;
}

interface DropdownProps {
  items: MenuItemType[];
  currentValue: string | number;
  onChangeValue: (itemValue: string | number) => void;
  className?: string;
  placeholder?: string;
  title: string;
  isToggleRight?: boolean;
  limitHeightItems?: boolean;
  ignoreValues?: (string | number)[];
}
const DropdownWithSearch: FC<DropdownProps> = ({
  items,
  onChangeValue,
  className,
  currentValue,
  placeholder = '選択してください',
  title,
  isToggleRight = false,
  limitHeightItems = false,
  ignoreValues = [],
}) => {
  const [show, setShow] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>();
  const currentItem = useMemo(() => {
    return items.find((i) => i.value === currentValue);
  }, [currentValue, items]);
  const [keyword, setKeyword] = useState(currentItem?.label || '');
  const showingItems = useMemo(() => {
    let currentItems = items;
    if (keyword) {
      currentItems = currentItems.filter(
        (itm) =>
          itm.label
            ?.toString()
            ?.toLowerCase()
            ?.includes(keyword.toLowerCase()) ||
          itm.value?.toString()?.toLowerCase()?.includes(keyword.toLowerCase())
      );
    }
    if (ignoreValues.length > 0) {
      const currentIgnoreValues = currentItem
        ? ignoreValues.filter((val) => val !== currentItem?.value)
        : ignoreValues;
      currentItems = currentItems.filter(
        (itm) => !currentIgnoreValues.includes(itm.value)
      );
    }
    return currentItems;
  }, [items, keyword, ignoreValues, currentItem]);
  useOutsideClick(dropdownRef, () => {
    if (show) setShow(false);
  });
  return (
    <div
      className={classNames(styles.dropdown, className ?? '')}
      ref={dropdownRef}
    >
      <div className={classNames(styles.value, 'value')}>
        <input
          type="text"
          placeholder={placeholder}
          value={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onClick={() => setShow(true)}
        />
        {/* {currentItem?.label ?? placeholder} */}
        {keyword && show && (
          <div className={styles.close}>
            <Icon
              name="close"
              width={14}
              height={14}
              onClick={() => {
                setKeyword('');
                onChangeValue(null);
              }}
            />
          </div>
        )}
        <div className={styles.caret}>
          <Icon
            name="sort-arrow"
            width={9}
            height={9}
            onClick={() => setShow(!show)}
          />
        </div>
      </div>
      <div
        className={classNames(styles.menuWrapper, {
          [styles.toogleRight]: isToggleRight,
          [styles.limitHeightItems]: limitHeightItems,
        })}
        style={{ display: show ? 'block' : 'none' }}
      >
        <div className={styles.items}>
          {title && <div className={styles.title}>{title}</div>}
          {showingItems.map((item, index) => (
            <div
              className={classNames(styles.item, 'item')}
              onClick={() => {
                onChangeValue(item.value);
                setKeyword(item.label);
                setShow(false);
              }}
              key={`item-${index}`}
            >
              <span className={styles.check}>
                {item.value === currentValue && (
                  <Icon name="checked" width={12} height={12} />
                )}
              </span>
              <span className={styles.label}>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownWithSearch;
