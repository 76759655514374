import { clone } from 'common/utils';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Icon from 'components/common/Icon';
import DateInput from 'components/common/DateInput';
import dayjs from 'lib/dayjs';
import { useContext, useEffect, useMemo, useState } from 'react';
import { InputProfileSectionContext } from '..';
import {
  BLANK_TEXT,
  InputInfoSubGroup,
  MAX_ONE_LINE_TEXT_LEN,
  MAX_TEXT_LEN,
} from '../Content';
import styles from '../InputProfileSection.module.scss';
import InputWithLabel from 'components/common/InputWithLabelV2';
import { ProfileJobDetailType, ProfileJobType } from 'service/user';
import { formatDatoToSend, showDateTo } from 'common/utils/user';
import useGlobalStore from 'store/global';

const DATE_FORMAT = 'YYYY年MM月';
export const InputWorkExperiences = () => {
  const [isEditMode, setEditMode] = useState(false);
  const { employmentTypes, fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );

  const options = useMemo(() => {
    return employmentTypes.map((type) => {
      return {
        label: type.name,
        value: type.id,
      };
    });
  }, [employmentTypes]);

  const [workExperiences, setWorkExperiences] = useState<ProfileJobType[]>(
    fetchedUserInfo?.profile_jobs || []
  );
  useEffect(() => {
    if (!isEditMode) {
      setWorkExperiences(fetchedUserInfo?.profile_jobs || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.profile_jobs]);

  const addBlankWorkExperience = () => {
    const newWorkExperiences = clone(workExperiences) as ProfileJobType[];
    newWorkExperiences.push({
      company_name: '',
      employment_type: 'full_time',
      from: formatDatoToSend(),
      to: formatDatoToSend(),
      details: [
        {
          department: '',
          from: formatDatoToSend(),
          to: formatDatoToSend(),
          description: '',
        },
      ],
    });
    setWorkExperiences(newWorkExperiences);
  };

  const deleteWorkExperience = (index: number) => {
    const newWorkExperiences = clone(workExperiences) as ProfileJobType[];
    newWorkExperiences.splice(index, 1);
    setWorkExperiences(newWorkExperiences);
  };

  const changeWorkExperienceInfo = ({
    index,
    attr,
    val,
  }: {
    index: number;
    attr: string;
    val: string;
  }) => {
    const newWorkExperiences = clone(workExperiences) as ProfileJobType[];
    if (!newWorkExperiences[index]) return;
    newWorkExperiences[index][attr] = val;
    setWorkExperiences(newWorkExperiences);
  };

  const addBlankExperience = (wExIndex: number) => {
    const newWorkExperiences = clone(workExperiences) as ProfileJobType[];
    if (!newWorkExperiences[wExIndex]) return;
    const newExs = clone(
      newWorkExperiences[wExIndex].details
    ) as ProfileJobDetailType[];
    newExs.push({
      department: '',
      from: formatDatoToSend(),
      to: formatDatoToSend(),
      description: '',
    });
    newWorkExperiences[wExIndex].details = newExs;
    setWorkExperiences(newWorkExperiences);
  };

  const deleteExperience = (wExIndex: number, exIndex: number) => {
    const newWorkExperiences = clone(workExperiences) as ProfileJobType[];
    if (!newWorkExperiences[wExIndex]) return;
    const newExs = clone(
      newWorkExperiences[wExIndex].details
    ) as ProfileJobDetailType[];
    newExs.splice(exIndex, 1);
    newWorkExperiences[wExIndex].details = newExs;
    setWorkExperiences(newWorkExperiences);
  };

  const changeExperienceInfo = ({
    workExpIndex,
    expIndex,
    attr,
    val,
  }: {
    workExpIndex: number;
    expIndex: number;
    attr: string;
    val: string;
  }) => {
    const newWorkExperiences = clone(workExperiences) as ProfileJobType[];
    if (!newWorkExperiences[workExpIndex]) return;
    const newExs = clone(
      newWorkExperiences[workExpIndex].details
    ) as ProfileJobDetailType[];
    if (!newExs[expIndex]) return;
    newExs[expIndex][attr] = val;
    newWorkExperiences[workExpIndex].details = newExs;
    setWorkExperiences(newWorkExperiences);
  };

  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );

  const downPositionWorkExperience = (index: number) => {
    if (index < 0 || index >= workExperiences.length - 1) return;
    const newWorkExperiences = clone(workExperiences) as ProfileJobType[];
    const currentEx = newWorkExperiences[index];
    if (!currentEx) return;
    newWorkExperiences[index] = newWorkExperiences[index + 1];
    newWorkExperiences[index + 1] = currentEx;
    setWorkExperiences(newWorkExperiences);
    setSnackbarMessage({ text: '位置を変更しました', type: 'success' });
  };

  const upPositionWorkExperience = (index: number) => {
    if (index === 0 || index > workExperiences.length - 1) return;
    const newWorkExperiences = clone(workExperiences) as ProfileJobType[];
    const currentEx = newWorkExperiences[index];
    if (!currentEx) return;
    newWorkExperiences[index] = newWorkExperiences[index - 1];
    newWorkExperiences[index - 1] = currentEx;
    setWorkExperiences(newWorkExperiences);
    setSnackbarMessage({ text: '位置を変更しました', type: 'success' });
  };

  useEffect(() => {
    if (isEditMode && workExperiences.length === 0) {
      addBlankWorkExperience();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);
  const hasError = useMemo(() => {
    return workExperiences.some(
      (ex) => !ex.company_name?.trim() || !ex.employment_type
    );
  }, [workExperiences]);
  return (
    <InputInfoSubGroup
      name="職歴"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      disableSave={hasError}
      errorText={hasError ? '必須項目を入力してください' : ''}
      onSave={() => {
        const newsExs = workExperiences.filter(
          (ex) => !!ex.company_name?.trim() && !!ex.employment_type
        );
        setWorkExperiences(newsExs);
        updateUserInfo({
          profile_jobs: newsExs,
        });
      }}
      onCancel={() => {
        setWorkExperiences(fetchedUserInfo?.profile_jobs || []);
      }}
    >
      {isEditMode ? (
        <div className={styles.workExperiencesEditMode}>
          {workExperiences.map((wEx, WEindex) => {
            return (
              <div className={styles.workExperience} key={`wEx-${WEindex}`}>
                <div className={styles.companyName}>
                  <span className={styles.exIndex}>{WEindex + 1}.</span>
                  <InputWithLabel
                    title="会社名"
                    required
                    requiredAlert={!wEx.company_name?.trim()}
                  >
                    <input
                      type="text"
                      name="company_name"
                      value={wEx.company_name}
                      className={styles.inputTextContentOneLine}
                      placeholder="会社名"
                      maxLength={MAX_ONE_LINE_TEXT_LEN}
                      onChange={(e) =>
                        changeWorkExperienceInfo({
                          index: WEindex,
                          attr: 'company_name',
                          val: e.target.value,
                        })
                      }
                    />
                  </InputWithLabel>
                  <div className={styles.deleteIcon}>
                    <Icon
                      name="delete-trash"
                      height={24}
                      width={24}
                      onClick={() => deleteWorkExperience(WEindex)}
                    />
                  </div>

                  <div className={styles.changePositionWrapper}>
                    <div className={styles.down}>
                      {WEindex < workExperiences.length - 1 && (
                        <Icon
                          name="up-position"
                          width={24}
                          height={24}
                          onClick={() => {
                            downPositionWorkExperience(WEindex);
                          }}
                        />
                      )}
                    </div>
                    {workExperiences.length > 1 && WEindex > 0 && (
                      <div className={styles.up}>
                        <Icon
                          name="up-position"
                          width={24}
                          height={24}
                          onClick={() => {
                            upPositionWorkExperience(WEindex);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.contractType}>
                  <InputWithLabel title="契約タイプ">
                    <Dropdown
                      items={options}
                      placeholder="契約タイプ"
                      limitHeightItems
                      className={styles.customDropdown}
                      currentValue={wEx.employment_type}
                      title=""
                      onChangeValue={(val: string) => {
                        changeWorkExperienceInfo({
                          index: WEindex,
                          attr: 'employment_type',
                          val: val,
                        });
                      }}
                    />
                  </InputWithLabel>
                </div>
                <div className={styles.timeInfo}>
                  <InputWithLabel
                    title="在籍期間"
                    style={{ marginRight: '10px' }}
                  >
                    <DateInput
                      date={wEx.from ? dayjs(wEx.from).toDate() : null}
                      setDate={(date) => {
                        changeWorkExperienceInfo({
                          index: WEindex,
                          attr: 'from',
                          val: formatDatoToSend(date),
                        });
                      }}
                      onlyMonthYear
                      showTimeSelect={false}
                      customDateFormat={DATE_FORMAT}
                    />
                  </InputWithLabel>
                  <span>~</span>
                  <InputWithLabel
                    title=" "
                    style={{ marginLeft: '10px', marginRight: '10px' }}
                  >
                    <DateInput
                      date={wEx.to ? dayjs(wEx.to).toDate() : null}
                      setDate={(date) => {
                        changeWorkExperienceInfo({
                          index: WEindex,
                          attr: 'to',
                          val: formatDatoToSend(date),
                        });
                      }}
                      onlyMonthYear
                      showTimeSelect={false}
                      customDateFormat={DATE_FORMAT}
                      dateNullText="現在"
                    />
                  </InputWithLabel>
                  <div
                    className={styles.checkboxWrapper}
                    onClick={() => {
                      changeWorkExperienceInfo({
                        index: WEindex,
                        attr: 'to',
                        val: wEx.to ? null : formatDatoToSend(),
                      });
                    }}
                  >
                    <input
                      type="checkbox"
                      name={`till-now-${WEindex}`}
                      id={`till-now-${WEindex}`}
                      readOnly
                      checked={!wEx.to}
                    />
                    <span>現在</span>
                  </div>
                </div>
                <span className={styles.timelineTitle}>詳細リスト</span>

                <ul className={styles.timeline}>
                  {(wEx.details || []).map((ex, index) => {
                    return (
                      <li
                        className={styles.event}
                        key={`wEx-${WEindex}-${index}`}
                      >
                        <InputWithLabel
                          title={
                            <div className={styles.exTitle}>
                              役割
                              <div className={styles.deleteIcon}>
                                <Icon
                                  name="delete-trash"
                                  height={24}
                                  width={24}
                                  onClick={() =>
                                    deleteExperience(WEindex, index)
                                  }
                                />
                              </div>
                            </div>
                          }
                        >
                          <input
                            type="text"
                            value={ex.department}
                            placeholder="役割"
                            maxLength={MAX_ONE_LINE_TEXT_LEN}
                            onChange={(e) =>
                              changeExperienceInfo({
                                workExpIndex: WEindex,
                                expIndex: index,
                                attr: 'department',
                                val: e.target.value,
                              })
                            }
                            className={styles.inputTextContentOneLine}
                          />
                        </InputWithLabel>
                        <div className={styles.dateInputGroup}>
                          <InputWithLabel
                            title="在籍期間"
                            style={{ marginRight: '10px' }}
                          >
                            <DateInput
                              date={ex.from ? dayjs(ex.from).toDate() : null}
                              setDate={(date) => {
                                changeExperienceInfo({
                                  workExpIndex: WEindex,
                                  expIndex: index,
                                  attr: 'from',
                                  val: formatDatoToSend(date),
                                });
                              }}
                              onlyMonthYear
                              showTimeSelect={false}
                              customDateFormat={DATE_FORMAT}
                            />
                          </InputWithLabel>
                          <span>~</span>
                          <InputWithLabel
                            title=" "
                            style={{ marginLeft: '10px', marginRight: '10px' }}
                          >
                            <DateInput
                              date={ex.to ? dayjs(ex.to).toDate() : null}
                              setDate={(date) => {
                                changeExperienceInfo({
                                  workExpIndex: WEindex,
                                  expIndex: index,
                                  attr: 'to',
                                  val: formatDatoToSend(date),
                                });
                              }}
                              dateNullText="現在"
                              onlyMonthYear
                              showTimeSelect={false}
                              customDateFormat={DATE_FORMAT}
                            />
                          </InputWithLabel>
                          <div
                            className={styles.checkboxWrapper}
                            onClick={() => {
                              changeExperienceInfo({
                                workExpIndex: WEindex,
                                expIndex: index,
                                attr: 'to',
                                val: ex.to ? null : formatDatoToSend(),
                              });
                            }}
                          >
                            <input
                              type="checkbox"
                              name={`till-now-${WEindex}-${index}`}
                              id={`till-now-${WEindex}-${index}`}
                              readOnly
                              checked={!ex.to}
                            />
                            <span>現在</span>
                          </div>
                        </div>
                        <InputWithLabel
                          title={`説明（${ex.description?.length}/${MAX_TEXT_LEN}）`}
                        >
                          <textarea
                            value={ex.description}
                            className={styles.inputTextContent}
                            maxLength={MAX_TEXT_LEN}
                            placeholder="例：【役割】
                            新規営業、動画制作のディレクション、広告運用などを担当。
                            【営業スタイル】
                            ・新規営業（インバウンド50%/アウトバウンド50%)
                            メールフォームからの営業、SNSを通じた営業、ホワイトペーパー作成
                            【他業務】
                            ・動画制作のディレクション（絵コンテ作成、フリーランス探し、ディレクション)
                            ・広告運用のサポート（YouTube広告、Twitter広告、Facebook広告）"
                            onChange={(e) => {
                              changeExperienceInfo({
                                workExpIndex: WEindex,
                                expIndex: index,
                                attr: 'description',
                                val: e.target.value,
                              });
                            }}
                            style={{ height: '100px' }}
                          />
                        </InputWithLabel>
                      </li>
                    );
                  })}
                  <div className={styles.addEx}>
                    <Button
                      text="役割を追加"
                      onClick={() => addBlankExperience(WEindex)}
                    />
                  </div>
                </ul>
              </div>
            );
          })}
          <div className={styles.addWorkEx}>
            <Button
              text="会社を追加"
              onClick={() => addBlankWorkExperience()}
            />
          </div>
        </div>
      ) : (
        <div className={styles.workExperiences}>
          {!workExperiences ||
            (workExperiences.length === 0 && (
              <div className={styles.infoShow}>{BLANK_TEXT}</div>
            ))}
          {(workExperiences || []).map((wEx, WEindex) => {
            return (
              <div className={styles.workExperience} key={`wEx-${WEindex}`}>
                <div className={styles.companyName}>{wEx.company_name}</div>
                <div className={styles.timeInfo}>
                  {
                    options.find((op) => op.value === wEx.employment_type)
                      ?.label
                  }{' '}
                  {`${showDateTo(wEx.from, DATE_FORMAT)} ~
                  ${showDateTo(wEx.to, DATE_FORMAT)}`}
                </div>
                <ul className={styles.timeline}>
                  {(wEx.details || []).map((ex, index) => {
                    return (
                      <li
                        className={styles.event}
                        key={`wEx-${WEindex}-${index}`}
                      >
                        <p className={styles.department}>
                          {ex.department || BLANK_TEXT}
                        </p>
                        <p className={styles.dateRange}>
                          {`${showDateTo(ex.from, DATE_FORMAT)} ~
                          ${showDateTo(ex.to, DATE_FORMAT)}`}
                        </p>
                        <span className={styles.textContent}>
                          {ex.description || BLANK_TEXT}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      )}
    </InputInfoSubGroup>
  );
};
