import Icon from 'components/common/Icon';
import React, { RefObject, useEffect } from 'react';
import { createContext, useState, Dispatch, SetStateAction } from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  getProfileMasterData,
  getUserProfileInfo,
  updateUserProfileInfo,
  UserProfileInfoType,
} from 'service/user';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import {
  ProfileSection,
  BasicInfoSection,
  ContactInfoSection,
  WorkExperiencesSection,
  StudyExperiencesSection,
  ConditionsInfoSection,
  ProgressBarSection,
} from './Content';
import styles from './InputProfileSection.module.scss';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { useRouter } from 'next/router';
import { POLICY_URL, USER_TERM_URL } from 'common/utils';
import { clickPolicyPopup, viewPolicyPopup } from 'common/utils/user';

interface InputProfileSectionContextType {
  educationLevels: { id: number; name: string }[];
  employmentTypes: { id: string; name: string }[];
  industryTypes: { id: number; name: string }[];
  languageLevels: { id: string; name: string }[];
  languages: {
    id: number;
    name_en: string;
    name_jap: string;
    code: string;
  }[];
  nationalities: { name: string; code: string; id: number }[];
  occupations: { id: number; name: string }[];
  salaryRanges: { id: number; name: string }[];
  timeChangeJobs: { id: string; name: string }[];
  educationStatuses: { id: string; name: string }[];
  onlyViewMode: boolean;
  fetchedUserInfo: UserProfileInfoType;
  updateUserInfo: (data: any) => void;
  changeJobRef: RefObject<HTMLDivElement>;
  isUpdating: boolean;
  isFetchingData: boolean;
  setEditingProfileCount: Dispatch<SetStateAction<number>>;
}

export const InputProfileSectionContext =
  createContext<InputProfileSectionContextType | null>(null);

const InputProfileSection = ({
  onlyViewMode,
  userId,
  setEditingProfileCount,
}: {
  onlyViewMode: boolean;
  userId: number;
  setEditingProfileCount: Dispatch<React.SetStateAction<number>>;
}) => {
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const currentUser = useStore((state) => state.currentUser);
  const [fetchedUserInfo, setFetchedUserInfo] = useState<UserProfileInfoType>();
  const [openCheckPopup, setOpenCheckPopup] = useState(false);
  const changeJobRef = React.createRef<HTMLDivElement>();
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const router = useRouter();
  useEffect(() => {
    setEditingProfileCount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  useEffect(() => {
    setEditingProfileCount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isFetched } = useQuery(
    'getMasterData',
    async () => {
      if (!isAuthInit) return Promise.reject();
      return await getProfileMasterData();
    },
    {
      refetchOnWindowFocus: false,
      enabled: isAuthInit,
    }
  );

  const {
    isFetched: isFetchedData,
    isLoading: isLoadingData,
    refetch: refetchInfo,
    isFetching: isFetchingData,
  } = useQuery(
    ['getUserInfoData', userId.toString(), onlyViewMode],
    async () => {
      if (!isAuthInit) return Promise.reject();
      return await getUserProfileInfo(userId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: isAuthInit,
      onSuccess: (data) => {
        setFetchedUserInfo(data?.data);
      },
    }
  );

  useEffect(() => {
    if (
      userId !== currentUser?.id ||
      fetchedUserInfo?.user_id !== currentUser?.id
    )
      return;
    if (fetchedUserInfo?.is_info_sharing_approved === false) {
      setOpenCheckPopup(true);
      viewPolicyPopup();
    } else {
      setOpenCheckPopup(false);
    }
  }, [
    userId,
    fetchedUserInfo?.is_info_sharing_approved,
    fetchedUserInfo?.user_id,
    currentUser?.id,
  ]);
  const { mutate: updateUserInfo, isLoading: isUpdating } = useMutation(
    async (inputData: any) => {
      return await updateUserProfileInfo(userId, inputData);
    },
    {
      onSuccess: () => {
        void refetchInfo();
        setSnackbarMessage({
          type: 'success',
          text: '保存しました',
        });
      },

      onError: () => {
        setSnackbarMessage({
          type: 'error',
          text: 'エラーが発生しました',
        });
      },
    }
  );

  if (!isFetched || !isFetchedData || isLoadingData)
    return (
      <div className={styles.loading}>
        <Icon name="loading-anim-3" width={30} height={30} />
      </div>
    );

  if (!fetchedUserInfo) return <></>;
  if (
    onlyViewMode &&
    !fetchedUserInfo?.is_basic_information_public &&
    !fetchedUserInfo?.is_education_public &&
    !fetchedUserInfo?.is_profile_job_public &&
    !fetchedUserInfo?.is_profile_public
  ) {
    return (
      <div className={styles.empty}>
        <Icon name="empty" width={96} height={96} />
        <div>プロフィール情報が公開されていません</div>
      </div>
    );
  }
  return (
    <InputProfileSectionContext.Provider
      value={{
        educationLevels: data?.data?.education_levels || [],
        employmentTypes: data?.data?.employment_types || [],
        industryTypes: data?.data?.industry_types || [],
        languageLevels: data?.data?.language_levels || [],
        languages: data?.data?.languages || [],
        nationalities: data?.data?.nationalities || [],
        occupations: data?.data?.occupations || [],
        salaryRanges: data?.data?.salary_ranges || [],
        timeChangeJobs: data?.data?.time_change_jobs || [],
        educationStatuses: data?.data?.education_statuses || [],
        onlyViewMode,
        setEditingProfileCount,
        fetchedUserInfo: fetchedUserInfo || ({} as UserProfileInfoType),
        updateUserInfo: updateUserInfo,
        changeJobRef: changeJobRef,
        isFetchingData,
        isUpdating,
      }}
    >
      <div className={styles.inputProfileSection}>
        {!onlyViewMode && <ProgressBarSection />}
        <ProfileSection />
        <WorkExperiencesSection />
        <StudyExperiencesSection />
        <BasicInfoSection />
        {!onlyViewMode && <ConditionsInfoSection />}
        {!onlyViewMode && <ContactInfoSection />}
        {!!currentUser && !onlyViewMode && openCheckPopup && (
          <ConfirmDialog
            open={openCheckPopup}
            title={`<div style='font-size: 13px;'><p>当社の転職支援を受けるに際し、</p><p>人材紹介事業の<a target='_blank' href='${USER_TERM_URL}'>利用規約</a>と<a target='_blank' href='${POLICY_URL}'>プライバシーポリシー</a></p><p>に同意頂く必要があります。</p><p>利用規約とプライバシーポリシーに同意して、</p><p>入力を行いますか？</p></div>`}
            actionCloseFnc={() => {
              clickPolicyPopup('disagree_btn');
              setOpenCheckPopup(false);
              void router.push('/');
            }}
            actionSelectedFnc={() => {
              clickPolicyPopup('agree_btn');
              setOpenCheckPopup(false);
              updateUserInfo({ is_info_sharing_approved: true });
            }}
            enableClickOutsideToClose={false}
            yesText="同意する"
            noText="同意しない"
          />
        )}
      </div>
    </InputProfileSectionContext.Provider>
  );
};

export default InputProfileSection;
