import { useContext, useEffect, useMemo, useState } from 'react';
import {
  BLANK_TEXT,
  InputInfoSubGroup,
  MAX_ONE_LINE_TEXT_LEN,
} from '../Content';
import styles from '../InputProfileSection.module.scss';
import InputWithLabel from 'components/common/InputWithLabelV2';
import { clone, kanaValid } from 'common/utils';
import { InputProfileSectionContext } from '..';

type ProfileNameType = {
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
};

const InputName = () => {
  const { fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const [isEditMode, setEditMode] = useState(false);
  const [nameObject, setNameObject] = useState<ProfileNameType>({
    firstName: fetchedUserInfo?.first_name || '',
    firstNameKana: fetchedUserInfo?.first_name_kana || '',
    lastName: fetchedUserInfo?.last_name || '',
    lastNameKana: fetchedUserInfo?.last_name_kana || '',
  } as ProfileNameType);
  const fullNameText = useMemo(() => {
    if (!nameObject.firstName && !nameObject.lastName) return '';
    return `${nameObject.firstName || ''} ${nameObject.lastName || ''}`;
  }, [nameObject]);
  const fullNameTextKana = useMemo(() => {
    if (!nameObject.firstNameKana && !nameObject.lastNameKana) return '';
    return `${nameObject.firstNameKana || ''} ${nameObject.lastNameKana || ''}`;
  }, [nameObject]);

  useEffect(() => {
    if (!isEditMode) {
      setNameObject({
        firstName: fetchedUserInfo?.first_name || '',
        firstNameKana: fetchedUserInfo?.first_name_kana || '',
        lastName: fetchedUserInfo?.last_name || '',
        lastNameKana: fetchedUserInfo?.last_name_kana || '',
      });
    }
  }, [
    fetchedUserInfo?.first_name,
    fetchedUserInfo?.first_name_kana,
    fetchedUserInfo?.last_name,
    fetchedUserInfo?.last_name_kana,
    isEditMode,
  ]);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'firstName' | 'lastName' | 'firstNameKana' | 'lastNameKana'
  ) => {
    const value = e.target.value;
    const newObject = clone(nameObject) as ProfileNameType;
    newObject[type] = value;
    setNameObject(newObject);
  };

  const firstNameKanaError = !kanaValid(nameObject?.firstNameKana);
  const lastNameKanaError = !kanaValid(nameObject?.lastNameKana);
  return (
    <InputInfoSubGroup
      name="名前"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      disableSave={firstNameKanaError || lastNameKanaError}
      onSave={() => {
        updateUserInfo({
          first_name: nameObject.firstName,
          first_name_kana: nameObject.firstNameKana,
          last_name: nameObject.lastName,
          last_name_kana: nameObject.lastNameKana,
        });
      }}
      onCancel={() => {
        setNameObject({
          firstName: fetchedUserInfo?.first_name || '',
          firstNameKana: fetchedUserInfo?.first_name_kana || '',
          lastName: fetchedUserInfo?.last_name || '',
          lastNameKana: fetchedUserInfo?.last_name_kana || '',
        });
      }}
    >
      {isEditMode ? (
        <div className={styles.inputNameEditMode}>
          <div className={styles.nameInputGroup}>
            <InputWithLabel
              style={{ width: '160px', marginRight: '10px' }}
              title="姓"
            >
              <input
                placeholder="姓"
                type="text"
                name="first_name"
                maxLength={MAX_ONE_LINE_TEXT_LEN}
                value={nameObject.firstName}
                onChange={(e) => handleChange(e, 'firstName')}
              />
            </InputWithLabel>
            <InputWithLabel style={{ width: '160px' }} title="名">
              <input
                placeholder="名"
                type="text"
                name="last_name"
                maxLength={MAX_ONE_LINE_TEXT_LEN}
                value={nameObject.lastName}
                onChange={(e) => handleChange(e, 'lastName')}
              />
            </InputWithLabel>
          </div>
          <div className={styles.nameInputGroup}>
            <InputWithLabel
              style={{ width: '160px', marginRight: '10px' }}
              error={firstNameKanaError ? '全角カナで入力してください' : ''}
              title="姓(カタカナ)"
            >
              <input
                placeholder="姓(カタカナ)"
                value={nameObject.firstNameKana}
                name="first_name_kana"
                type="text"
                maxLength={MAX_ONE_LINE_TEXT_LEN}
                onChange={(e) => handleChange(e, 'firstNameKana')}
              />
            </InputWithLabel>
            <InputWithLabel
              style={{ width: '160px' }}
              error={lastNameKanaError ? '全角カナで入力してください' : ''}
              title="名(カタカナ)"
            >
              <input
                placeholder="名(カタカナ)"
                name="last_name_kana"
                type="text"
                value={nameObject.lastNameKana}
                maxLength={MAX_ONE_LINE_TEXT_LEN}
                onChange={(e) => handleChange(e, 'lastNameKana')}
              />
            </InputWithLabel>
          </div>
        </div>
      ) : (
        <div className={styles.infoShow}>
          {fullNameText && <p>{fullNameText}</p>}
          {fullNameTextKana && (
            <p style={{ marginTop: '5px' }}>{fullNameTextKana}</p>
          )}
          {!fullNameText && !fullNameTextKana && <p>{BLANK_TEXT}</p>}
        </div>
      )}
    </InputInfoSubGroup>
  );
};

export default InputName;
