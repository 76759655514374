import { clone } from 'common/utils';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import DropdownWithSearch from 'components/common/DropdownWithSearch';
import Icon from 'components/common/Icon';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ProfileLanguageType } from 'service/user';
import { InputProfileSectionContext } from '..';
import { BLANK_TEXT, InputInfoSubGroup } from '../Content';
import styles from '../InputProfileSection.module.scss';

export const InputLanguages = () => {
  const { languages, languageLevels, fetchedUserInfo, updateUserInfo } =
    useContext(InputProfileSectionContext);
  const [currentLanguages, setCurrentLanguages] = useState<
    ProfileLanguageType[]
  >(fetchedUserInfo?.profile_languages || []);

  const languagesOptions = useMemo(() => {
    return languages.map((lan) => {
      return {
        label: lan.name_jap,
        value: lan.id,
      };
    });
  }, [languages]);

  const languageLevelsOptions = useMemo(() => {
    return languageLevels.map((lv) => {
      return {
        label: lv.name,
        value: lv.id,
      };
    });
  }, [languageLevels]);

  const [isEditMode, setEditMode] = useState(false);

  const ignoreLanguageIds = useMemo(() => {
    return currentLanguages.map((lan) => lan.language_id);
  }, [currentLanguages]);
  useEffect(() => {
    if (!isEditMode) {
      setCurrentLanguages(fetchedUserInfo?.profile_languages || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.profile_languages]);
  const addBlankLanguage = () => {
    const newLanguages = clone(currentLanguages) as ProfileLanguageType[];
    newLanguages.push({
      language_id: null,
      level: '',
    });
    setCurrentLanguages(newLanguages);
  };

  const handleChangeLanguage = (index: number, languageId: number) => {
    const newLanguages = clone(currentLanguages) as ProfileLanguageType[];
    const currentLan = newLanguages[index];
    const newLan = languages.find((lan) => lan.id === languageId);
    if (!newLan) return;
    const newLanguage = {
      language_id: newLan.id,
      level: currentLan?.level,
    };
    newLanguages[index] = newLanguage;
    setCurrentLanguages(newLanguages);
  };

  const handleChangeLevel = (index: number, levelId: string) => {
    const newLanguages = clone(currentLanguages) as ProfileLanguageType[];
    const newLevel = languageLevels.find((lv) => lv.id === levelId)?.id;
    newLanguages[index].level = newLevel;
    setCurrentLanguages(newLanguages);
  };

  const deleteLanguage = (index: number) => {
    const newLanguages = clone(currentLanguages) as ProfileLanguageType[];
    newLanguages.splice(index, 1);
    setCurrentLanguages(newLanguages);
  };

  useEffect(() => {
    if (isEditMode && currentLanguages.length === 0) {
      addBlankLanguage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  return (
    <InputInfoSubGroup
      name="言語"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        const newLans = currentLanguages.filter(
          (lan) => !!lan.language_id && lan.language_id > 0 && !!lan.level
        );
        setCurrentLanguages(newLans);
        updateUserInfo({
          profile_languages: newLans,
        });
      }}
      onCancel={() => {
        setCurrentLanguages(fetchedUserInfo?.profile_languages || []);
      }}
    >
      {isEditMode ? (
        <div className={styles.languagesEditMode}>
          {currentLanguages.map((lan, index) => {
            return (
              <div
                className={styles.languageItem}
                key={`language-object-${lan.id}-${index}`}
              >
                <div className={styles.index}>{index + 1}.</div>
                <div className={styles.languageSelect}>
                  <DropdownWithSearch
                    items={languagesOptions}
                    placeholder="言語を検索する"
                    limitHeightItems
                    className={styles.customDropdown}
                    currentValue={lan.language_id}
                    title=""
                    ignoreValues={ignoreLanguageIds}
                    onChangeValue={(val: number) =>
                      handleChangeLanguage(index, val)
                    }
                  />
                </div>
                <div className={styles.languageLevelSelect}>
                  <Dropdown
                    items={languageLevelsOptions}
                    placeholder="言語のレベル"
                    limitHeightItems
                    className={styles.customDropdown}
                    currentValue={lan.level}
                    title=""
                    onChangeValue={(val: string) =>
                      handleChangeLevel(index, val)
                    }
                  />
                </div>
                <div className={styles.deleteIcon}>
                  <Icon
                    name="delete-trash"
                    height={24}
                    width={24}
                    onClick={() => deleteLanguage(index)}
                  />
                </div>
              </div>
            );
          })}
          <div className={styles.addLanguage}>
            <Button text="言語を追加" onClick={() => addBlankLanguage()} />
          </div>
        </div>
      ) : (
        <div className={styles.languages}>
          {(!currentLanguages || currentLanguages.length === 0) && (
            <div className={styles.infoShow}>{BLANK_TEXT}</div>
          )}
          {(currentLanguages || []).map((lan, index) => {
            return (
              <div
                className={styles.languageItem}
                key={`language-object-${lan.id}-${index}`}
              >
                <span className={styles.name}>{lan?.language?.name_jap}</span>
                <span className={styles.level}>
                  {
                    languageLevelsOptions.find((op) => op.value === lan.level)
                      ?.label
                  }
                </span>
              </div>
            );
          })}
        </div>
      )}
    </InputInfoSubGroup>
  );
};
