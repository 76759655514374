import { clone } from 'common/utils';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import DateInput from 'components/common/DateInput';
import dayjs from 'lib/dayjs';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  BLANK_TEXT,
  InputInfoSubGroup,
  MAX_ONE_LINE_TEXT_LEN,
  MAX_TEXT_LEN,
} from '../Content';
import styles from '../InputProfileSection.module.scss';
import InputWithLabel from 'components/common/InputWithLabelV2';
import classNames from 'classnames';
import { ProfileEducationType } from 'service/user';
import { InputProfileSectionContext } from '..';
import Dropdown from 'components/common/Dropdown';
import { formatDatoToSend, showDateTo } from 'common/utils/user';
import useGlobalStore from 'store/global';
const DATE_FORMAT = 'YYYY年MM月';

export const InputStudyExperiences = () => {
  const {
    fetchedUserInfo,
    updateUserInfo,
    educationLevels,
    educationStatuses,
  } = useContext(InputProfileSectionContext);
  const [isEditMode, setEditMode] = useState(false);
  const [studyExperiences, setStudyExperiences] = useState<
    ProfileEducationType[]
  >(fetchedUserInfo?.profile_educations || []);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );

  const educationLevelsOptions = useMemo(() => {
    return educationLevels.map((lv) => {
      return {
        label: lv.name,
        value: lv.id,
      };
    });
  }, [educationLevels]);

  const educationStatusOptions = useMemo(() => {
    return educationStatuses.map((lv) => {
      return {
        label: lv.name,
        value: lv.id,
      };
    });
  }, [educationStatuses]);
  useEffect(() => {
    if (!isEditMode) {
      setStudyExperiences(fetchedUserInfo?.profile_educations || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserInfo?.profile_educations]);

  const addBlankStudyExperience = () => {
    const newExps = clone(studyExperiences) as ProfileEducationType[];
    newExps.push({
      school_name: '',
      from: formatDatoToSend(),
      to: formatDatoToSend(),
      description: '',
      education_level_id: 6,
      status: 'graduated',
    });
    setStudyExperiences(newExps);
  };

  const deleteStudyExperience = (index: number) => {
    const newExps = clone(studyExperiences) as ProfileEducationType[];
    newExps.splice(index, 1);
    setStudyExperiences(newExps);
  };

  const downPositionStudyExperience = (index: number) => {
    if (index < 0 || index >= studyExperiences.length - 1) return;
    const newExperiences = clone(studyExperiences) as ProfileEducationType[];
    const currentEx = newExperiences[index];
    if (!currentEx) return;
    newExperiences[index] = newExperiences[index + 1];
    newExperiences[index + 1] = currentEx;
    setStudyExperiences(newExperiences);
    setSnackbarMessage({ text: '位置を変更しました', type: 'success' });
  };

  const upPositionStudyExperience = (index: number) => {
    if (index === 0 || index > studyExperiences.length - 1) return;
    const newExperiences = clone(studyExperiences) as ProfileEducationType[];
    const currentEx = newExperiences[index];
    if (!currentEx) return;
    newExperiences[index] = newExperiences[index - 1];
    newExperiences[index - 1] = currentEx;
    setStudyExperiences(newExperiences);
    setSnackbarMessage({ text: '位置を変更しました', type: 'success' });
  };

  const handleChangeExInfo = ({
    index,
    attr,
    val,
  }: {
    index: number;
    attr: string;
    val: string | number;
  }) => {
    const newExps = clone(studyExperiences) as ProfileEducationType[];
    if (!newExps[index]) return;
    newExps[index][attr] = val;
    setStudyExperiences(newExps);
  };
  useEffect(() => {
    if (isEditMode && studyExperiences.length === 0) {
      addBlankStudyExperience();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);
  const hasError = useMemo(() => {
    return studyExperiences.some(
      (ex) => !ex.school_name?.trim() || !ex.education_level_id || !ex.status
    );
  }, [studyExperiences]);

  return (
    <InputInfoSubGroup
      name="最終学歴"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      disableSave={hasError}
      errorText={hasError ? '必須項目を入力してください' : ''}
      onSave={() => {
        const newExs = studyExperiences.filter(
          (ex) =>
            !!ex.school_name?.trim() && !!ex.education_level_id && !!ex.status
        );
        setStudyExperiences(newExs);
        updateUserInfo({
          profile_educations: newExs,
        });
      }}
      onCancel={() => {
        setStudyExperiences(fetchedUserInfo?.profile_educations || []);
      }}
    >
      {isEditMode ? (
        <div className={styles.studyExperiencesEditMode}>
          {studyExperiences.map((stdEx, index) => {
            return (
              <div className={styles.studyExperience} key={`studyEx-${index}`}>
                <div className={styles.studyName}>
                  <span className={styles.exIndex}>{index + 1}.</span>
                  <InputWithLabel
                    required
                    title={`学校名`}
                    style={{ marginRight: '15px', width: '200px' }}
                    requiredAlert={!stdEx.school_name?.trim()}
                  >
                    <input
                      type="text"
                      value={stdEx.school_name}
                      className={styles.inputTextContentOneLine}
                      placeholder="学校名"
                      name="school_name"
                      maxLength={MAX_ONE_LINE_TEXT_LEN}
                      onChange={(e) =>
                        handleChangeExInfo({
                          index,
                          attr: 'school_name',
                          val: e.target.value,
                        })
                      }
                    />
                  </InputWithLabel>
                  <InputWithLabel title="タイプ">
                    <Dropdown
                      items={educationLevelsOptions}
                      placeholder="タイプを選択"
                      limitHeightItems
                      className={styles.customDropdown}
                      currentValue={stdEx.education_level_id}
                      title=""
                      onChangeValue={(val: number) =>
                        handleChangeExInfo({
                          index,
                          attr: 'education_level_id',
                          val: val,
                        })
                      }
                    />
                  </InputWithLabel>
                  <div className={styles.deleteIcon}>
                    <Icon
                      name="delete-trash"
                      height={24}
                      width={24}
                      onClick={() => deleteStudyExperience(index)}
                    />
                  </div>
                  <div className={styles.changePositionWrapper}>
                    <div className={styles.down}>
                      {index < studyExperiences.length - 1 && (
                        <Icon
                          name="up-position"
                          width={24}
                          height={24}
                          onClick={() => {
                            downPositionStudyExperience(index);
                          }}
                        />
                      )}
                    </div>
                    {studyExperiences.length > 1 && index > 0 && (
                      <div className={styles.up}>
                        <Icon
                          name="up-position"
                          width={24}
                          height={24}
                          onClick={() => {
                            upPositionStudyExperience(index);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.status}>
                  <InputWithLabel title="ステータス">
                    <Dropdown
                      items={educationStatusOptions}
                      placeholder="ステータスを選択"
                      limitHeightItems
                      className={styles.customDropdown}
                      currentValue={stdEx.status}
                      title=""
                      onChangeValue={(val: number) =>
                        handleChangeExInfo({
                          index,
                          attr: 'status',
                          val: val,
                        })
                      }
                    />
                  </InputWithLabel>
                </div>
                <div className={styles.studyTime}>
                  <InputWithLabel
                    title="在学期間"
                    style={{ marginRight: '10px', width: '150px' }}
                  >
                    <DateInput
                      date={stdEx.from ? dayjs(stdEx.from).toDate() : null}
                      setDate={(date) => {
                        handleChangeExInfo({
                          index,
                          attr: 'from',
                          val: formatDatoToSend(date),
                        });
                      }}
                      onlyMonthYear
                      showTimeSelect={false}
                      customDateFormat={DATE_FORMAT}
                    />
                  </InputWithLabel>
                  <span>~</span>
                  <InputWithLabel
                    title=" "
                    style={{
                      width: '150px',
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                  >
                    <DateInput
                      date={stdEx.to ? dayjs(stdEx.to).toDate() : null}
                      onlyMonthYear
                      setDate={(date) => {
                        handleChangeExInfo({
                          index,
                          attr: 'to',
                          val: formatDatoToSend(date),
                        });
                      }}
                      dateNullText="現在"
                      showTimeSelect={false}
                      customDateFormat={DATE_FORMAT}
                    />
                  </InputWithLabel>
                  <div
                    className={styles.checkboxWrapper}
                    onClick={() => {
                      handleChangeExInfo({
                        index,
                        attr: 'to',
                        val: stdEx.to ? null : formatDatoToSend(),
                      });
                    }}
                  >
                    <input
                      type="checkbox"
                      name={`till-now-${index}`}
                      id={`till-now-${index}`}
                      readOnly
                      checked={!stdEx.to}
                    />
                    <span>現在</span>
                  </div>
                </div>
                <div className={styles.studyExDes}>
                  <InputWithLabel
                    title={`説明（${stdEx.description?.length}/${MAX_TEXT_LEN}）`}
                  >
                    <textarea
                      value={stdEx.description}
                      maxLength={MAX_TEXT_LEN}
                      className={styles.inputTextContent}
                      placeholder="例：専修大学では、経営学部に所属しておりました。組織についてや人の意思決定について興味があり、ゼミでは「組織論」や「意思決定理論」について学面と実際の組織での体験を通じて学んでおりました。
                      その他、スターバックスコーヒージャパンでアルバイトもしていました。時間帯責任者としてマネジメントをしたり、コーヒーに興味を持ち、お客様向けにコーヒーセミナーを開催したりしておりました。"
                      onChange={(e) => {
                        handleChangeExInfo({
                          index,
                          attr: 'description',
                          val: e.target.value,
                        });
                      }}
                      style={{ height: '100px' }}
                    />
                  </InputWithLabel>
                </div>
              </div>
            );
          })}
          <div className={styles.addSchool}>
            <Button
              text="学校を追加"
              onClick={() => addBlankStudyExperience()}
            />
          </div>
        </div>
      ) : (
        <div className={styles.studyExperiences}>
          {(!studyExperiences || studyExperiences.length === 0) && (
            <div className={styles.infoShow}>{BLANK_TEXT}</div>
          )}

          {studyExperiences.map((stdEx, index) => {
            return (
              <div className={styles.studyExperience} key={`studyEx-${index}`}>
                <span className={styles.studyName}>
                  {stdEx.school_name} -{' '}
                  {educationLevelsOptions?.find(
                    (lv) => lv.value === stdEx.education_level_id
                  )?.label || BLANK_TEXT}{' '}
                  -{' '}
                  {educationStatusOptions?.find(
                    (lv) => lv.value === stdEx.status
                  )?.label || BLANK_TEXT}
                </span>
                <span className={styles.studyTime}>
                  {`${showDateTo(stdEx.from, DATE_FORMAT)} ~ ${showDateTo(
                    stdEx.to,
                    DATE_FORMAT
                  )}`}
                </span>
                <span
                  className={classNames(
                    styles.textContent,
                    styles.studyContent
                  )}
                >
                  {stdEx.description || BLANK_TEXT}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </InputInfoSubGroup>
  );
};
