import { useQuery } from 'react-query';
import useGlobalStore from 'store/global';

import styles from './UserCoursesList.module.scss';
import { getUserCoursesList } from 'service/course';
import HorizontalCourseItemView from 'components/common/HorizontalCourseItemView';
import { useRef } from 'react';
import Icon from 'components/common/Icon';
import { User } from 'common/interfaces/api';

export default function UserCoursesList({
  creator,
}: {
  creator: User;
}): JSX.Element {
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const coursesListRef = useRef<HTMLDivElement>();
  const { data, isFetched } = useQuery(
    ['getUserCoursesList', creator?.id?.toString()],
    async () => {
      if (!isAuthInit || !creator?.id) return Promise.reject();
      return await getUserCoursesList(creator?.id);
    },
    {
      enabled: isAuthInit,
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );
  const courses = data?.data?.courses || [];
  const showNextBack = courses.length > 2;
  if (courses.length === 0) {
    return <></>;
  }
  const scrollShowMore = (offset: number) => {
    if (coursesListRef.current) {
      coursesListRef.current.scrollLeft += offset;
    }
  };
  return (
    <div className={styles.coursesListWrapper}>
      <div className={styles.sectionTitle}>
        {creator?.name}さんのコース（{courses.length}件）
      </div>
      {isFetched && (
        <div className={styles.coursesList} ref={coursesListRef}>
          {showNextBack && (
            <div
              className={styles.backBtn}
              onClick={() => {
                scrollShowMore(-250);
              }}
            >
              <Icon name="foward-arrow" width={24} height={24} />
            </div>
          )}
          {showNextBack && (
            <div
              className={styles.nextBtn}
              onClick={() => {
                scrollShowMore(250);
              }}
            >
              <Icon name="foward-arrow" width={24} height={24} />
            </div>
          )}

          {courses.map((course, index) => {
            return (
              <HorizontalCourseItemView
                course={course}
                key={`${course?.id}-${index}`}
                size={
                  courses.length === 1
                    ? 'big'
                    : courses.length === 2
                    ? 'medium'
                    : 'small'
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
