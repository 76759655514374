import { useContext, useEffect, useMemo, useState } from 'react';
import {
  BLANK_TEXT,
  InputInfoSubGroup,
  MAX_ONE_LINE_TEXT_LEN,
} from '../Content';
import styles from '../InputProfileSection.module.scss';
import InputWithLabel from 'components/common/InputWithLabelV2';
// import classNames from 'classnames';
import { useMutation } from 'react-query';
import { AddressResultType, AddressType } from 'common/interfaces/api';
import { clone } from 'common/utils';
import { getAddressByZip } from 'service/user';
import useGlobalStore from 'store/global';
import Icon from 'components/common/Icon';
import { InputProfileSectionContext } from '..';
const InputAddress = () => {
  const [isEditMode, setEditMode] = useState(false);
  const { fetchedUserInfo, updateUserInfo } = useContext(
    InputProfileSectionContext
  );
  const [addressObject, setAddressObject] = useState<AddressType>({
    zip_1: fetchedUserInfo?.address_zip1 || '',
    zip_2: fetchedUserInfo?.address_zip2 || '',
    kenName: fetchedUserInfo?.address_prefecture || '',
    cityName: fetchedUserInfo?.address_city || '',
    townName: fetchedUserInfo?.address_street || '',
    roomInfo: fetchedUserInfo?.address_building_room || '',
  });
  const addressText = useMemo(() => {
    if (
      !addressObject.zip_1 &&
      !addressObject.zip_2 &&
      !addressObject.kenName &&
      !addressObject.cityName &&
      !addressObject.townName &&
      !addressObject.roomInfo
    )
      return '';
    const line1 =
      addressObject.zip_1 || addressObject.zip_2
        ? `〒${`${addressObject.zip_1 || ''} - `}${addressObject.zip_2 || ''}\n`
        : '';
    return `${line1}${addressObject.kenName || ''} ${
      addressObject.cityName || ''
    } ${addressObject.townName || ''} ${addressObject.roomInfo || ''}`;
  }, [addressObject]);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  useEffect(() => {
    if (!isEditMode) {
      setAddressObject({
        zip_1: fetchedUserInfo?.address_zip1 || '',
        zip_2: fetchedUserInfo?.address_zip2 || '',
        kenName: fetchedUserInfo?.address_prefecture || '',
        cityName: fetchedUserInfo?.address_city || '',
        townName: fetchedUserInfo?.address_street || '',
        roomInfo: fetchedUserInfo?.address_building_room || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedUserInfo?.address_building_room,
    fetchedUserInfo?.address_city,
    fetchedUserInfo?.address_prefecture,
    fetchedUserInfo?.address_street,
    fetchedUserInfo?.address_zip1,
    fetchedUserInfo?.address_zip2,
  ]);

  const { mutate: searchAddress, isLoading: isSearchingAddress } = useMutation(
    async () => {
      const zip = `${addressObject.zip_1}-${addressObject.zip_2}`;
      return await getAddressByZip(zip);
    },
    {
      onSuccess: (data: AddressResultType) => {
        const result = data.data;
        const newAddress = clone(addressObject) as AddressType;
        newAddress.kenName = result.ken_name;
        newAddress.cityName = result.city_name;
        newAddress.townName = result.town_name;
        setAddressObject(newAddress);
      },
      onError: () => {
        setSnackbarMessage({
          type: 'error',
          text: '入力された郵便番号から住所を見つけられませんでした',
        });
      },
    }
  );

  const handleChangeAddress = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'zip_1' | 'zip_2' | 'townName' | 'kenName' | 'cityName' | 'roomInfo'
  ) => {
    const value = e.target.value;
    const newAddressObject = clone(addressObject) as AddressType;
    newAddressObject[type] = value;
    setAddressObject(newAddressObject);
  };

  return (
    <InputInfoSubGroup
      name="住所"
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      onSave={() => {
        updateUserInfo({
          address_zip1: addressObject?.zip_1,
          address_zip2: addressObject?.zip_2,
          address_prefecture: addressObject?.kenName,
          address_city: addressObject?.cityName,
          address_street: addressObject?.townName,
          address_building_room: addressObject?.roomInfo,
        });
      }}
      onCancel={() => {
        setAddressObject({
          zip_1: fetchedUserInfo?.address_zip1 || '',
          zip_2: fetchedUserInfo?.address_zip2 || '',
          kenName: fetchedUserInfo?.address_prefecture || '',
          cityName: fetchedUserInfo?.address_city || '',
          townName: fetchedUserInfo?.address_street || '',
          roomInfo: fetchedUserInfo?.address_building_room || '',
        });
      }}
    >
      {isEditMode ? (
        <div className={styles.inputAdressEditMode}>
          <div className={styles.addressInputGroup}>
            <InputWithLabel style={{ width: '110px' }}>
              <input
                placeholder="郵便番号"
                type="text"
                name="zip_1"
                value={addressObject.zip_1}
                maxLength={3}
                onChange={(e) => handleChangeAddress(e, 'zip_1')}
              />
            </InputWithLabel>
            <div className={styles.devide}>-</div>
            <InputWithLabel style={{ width: '110px' }}>
              <input
                placeholder="郵便番号"
                type="text"
                name="zip_2"
                value={addressObject.zip_2}
                maxLength={4}
                onChange={(e) => handleChangeAddress(e, 'zip_2')}
              />
            </InputWithLabel>
            {isSearchingAddress ? (
              <div className={styles.searchBtn}>
                <Icon name="loading-anim-3" width={24} height={24} />
              </div>
            ) : (
              <div
                className={styles.searchBtn}
                onClick={() => {
                  if (!addressObject.zip_1 || !addressObject.zip_2) {
                    setSnackbarMessage({
                      type: 'error',
                      text: '郵便番号を入力してください',
                    });
                    return;
                  }
                  searchAddress();
                }}
              >
                検索
              </div>
            )}
          </div>
          <div className={styles.addressInputGroup}>
            <InputWithLabel style={{ width: '150px', marginRight: '10px' }}>
              <input
                placeholder="都道府県"
                name="kenName"
                type="text"
                value={addressObject.kenName}
                maxLength={MAX_ONE_LINE_TEXT_LEN}
                onChange={(e) => handleChangeAddress(e, 'kenName')}
              />
            </InputWithLabel>
            <InputWithLabel style={{ width: '150px' }}>
              <input
                placeholder="市区町村"
                name="cityName"
                type="text"
                value={addressObject.cityName}
                maxLength={MAX_ONE_LINE_TEXT_LEN}
                onChange={(e) => handleChangeAddress(e, 'cityName')}
              />
            </InputWithLabel>
          </div>
          <div className={styles.addressInputGroup}>
            <InputWithLabel style={{ width: '310px' }}>
              <input
                placeholder="番地以降"
                value={addressObject.townName}
                name="townName"
                type="text"
                maxLength={MAX_ONE_LINE_TEXT_LEN}
                onChange={(e) => handleChangeAddress(e, 'townName')}
              />
            </InputWithLabel>
          </div>
          <div className={styles.addressInputGroup}>
            <InputWithLabel style={{ width: '310px' }}>
              <input
                placeholder="建物名・部屋番号"
                name="roomInfo"
                type="text"
                value={addressObject.roomInfo}
                maxLength={MAX_ONE_LINE_TEXT_LEN}
                onChange={(e) => handleChangeAddress(e, 'roomInfo')}
              />
            </InputWithLabel>
          </div>
        </div>
      ) : (
        <div className={styles.infoShow}>{addressText || BLANK_TEXT}</div>
      )}
    </InputInfoSubGroup>
  );
};

export default InputAddress;
